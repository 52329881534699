<div [class.loader-error]="isError" class="loader-container">
    <div class="loader-progress-bar-background" *ngIf="percentageCompletion!=-1">
        <div class="loader-progress-bar" [ngStyle]="{ 'width.%': percentageCompletion }"></div>
    </div>
    <!-- use angular material spinner here if percentage completion is -1 with colour as #ff5100 and not accent-->
    <mat-spinner *ngIf="percentageCompletion==-1" [color]="'primary'" [diameter]="50"></mat-spinner>


    <div *ngIf="!isError" class="loader-text">{{ loadingText }}</div>
    <div *ngIf="isError" class="loader-text loading-text-error">Something went wrong, please try again later...</div>
</div>