import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login-popup',
  standalone: true,  // Makes this component standalone
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule
  ]
})
export class LoginPopupComponent implements OnInit {
  loginForm: FormGroup | any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  loginFailed = false;
  networkFailed=false;
  loginConcurrentFailed=false;
  
  ngOnInit(): void {
    // Initialize the form with validators
    console.log(this.data)
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // Handle form submission
  onSubmit(): void {
    if (this.loginForm.valid) {
      // Call the function passed as a prop
      this.data.onSubmit(this.loginForm.value, this.onLoginFailure.bind(this), this.onNetworkFailure.bind(this), this.onLoginConcurrentFailed.bind(this));
      // this.dialogRef.close(); // Close dialog after submitting
    }
  }

  onLoginFailure(): void {
    this.loginFailed = true;
  }

  onNetworkFailure(): void {
    this.networkFailed = true;
  }
  
  onLoginConcurrentFailed(): void {
    this.loginFailed = true
  }

  // Getter for form controls
  get f() { return this.loginForm.controls; }
}
