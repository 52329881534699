import { Component, Input, Output, EventEmitter, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-invalidity-dashboard',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './invalidity-dashboard.component.html',
  styleUrls: ['./invalidity-dashboard.component.css']
})
export class InvalidityDashboardComponent implements OnInit {
  // Input summary data (each patent with properties like patentNumber, title, priorityDate, assignee, score)
  
  @Input() selectedPatentIndex: any=null;

  @Input() summaryData: any[] = [];

  ngOnInit(): void {
    
  }
  
/**
 * getInvalidityStats()
 * Computes summary statistics from the summaryData array.
 * It calculates the total number of patents, and counts how many fall into low (score 1–3),
 * medium (score 4–6), and high (score 7–10) invalidity ranges.
 */
getInvalidityStats() {
  let total = this.summaryData.length;
  let lowCount = 0;   // Score 1-3
  let midCount = 0;   // Score 4-6
  let highCount = 0;  // Score 7-10

  this.summaryData.forEach((p: any) => {
    const s = p.score || 0;
    if (s > 0 && s <= 3) lowCount++;
    else if (s >= 4 && s <= 6) midCount++;
    else if (s >= 7) highCount++;
  });

  return { total, lowCount, midCount, highCount };
}

/**
 * viewReportForPatent(index: number)
 * Triggered when a user clicks "View Report" in the dashboard table.
 * This function sets the selected patent index so that you can show
 * detailed information (for example, by switching to a summary or detailed view).
 */
viewReportForPatent(index: number) {
  this.selectedPatentIndex = index;
  // Optionally, you can change the stage (e.g., back to stage 4) to display details.
  // Example: this.currentStage = 4;
}

}
