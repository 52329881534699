import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-links-dialog',
  template: `
    <div class="dialog-header">
      <h2>Links</h2>
      <span class="close-icon" (click)="onClose()">&#x2716;</span>
    </div>
    <div class="dialog-content">
      <!-- <h3>Links</h3> -->
      <div class="claims-list">
<div *ngFor="let link of data.links; let i=index" >
  <b>Link {{i+1}}:</b> <a href="{{link}}" target="_blank">{{link}}</a>
      </div>
</div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  styleUrls: ['./links-dialog.component.css']
})
export class LinksDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LinksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
