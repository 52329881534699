import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class ProgressBarComponent implements OnChanges {
  @Input() percentageCompletion: number = 0;
  @Input() activeStage: number = 1;

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
    console.log(changes['percentageCompletion']);
    if (changes['percentageCompletion'] && this.percentageCompletion > 100) {
      this.percentageCompletion = 100;
    } else if (changes['percentageCompletion'] && this.percentageCompletion < 0) {
      this.percentageCompletion = 0;
    }
    this.percentageCompletion = changes['percentageCompletion'].currentValue;
  }
}