import { Component } from '@angular/core';

@Component({
  selector: 'app-score-legend',
  standalone: true,
  imports: [],
  templateUrl: './score-legend.component.html',
  styleUrl: './score-legend.component.css'
})
export class ScoreLegendComponent {

}
