<h2 mat-dialog-title>Login</h2>
<mat-dialog-content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" (submit)="onSubmit()">
        <div *ngIf="data.logoutMessage" class="error-message">
            <span [innerHTML]="data.logoutMessage"></span>
        </div>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" required>
            <mat-error *ngIf="f.username.hasError('required')">Username is required</mat-error>
            <mat-error *ngIf="f.username.hasError('minlength')">Username must be at least 3 characters long</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" required>
            <mat-error *ngIf="f.password.hasError('required')">Password is required</mat-error>
            <mat-error *ngIf="f.password.hasError('minlength')">Password must be at least 6 characters long</mat-error>
        </mat-form-field>

        <div *ngIf="networkFailed" class="error-message">
            Something went wrong, Please try again.
        </div>
        <div *ngIf="loginConcurrentFailed" class="error-message">
            <b>Session Limit</b><br> Maximum number of sessions exceeded, Please try again later or contact the product team.
        </div>
        <div *ngIf="loginFailed" class="error-message">
            Invalid Username or Password
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="loginForm.invalid">
    Login
  </button>
</mat-dialog-actions>