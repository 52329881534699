<div class="progress-container">
    <div class="stage-container">

        <div class="stage" [ngClass]="{ active: activeStage >= 1 }">Prior Art Search</div>
        <div class="stage" [ngClass]="{ active: activeStage >= 2 }">Industry Analysis</div>
        <div class="stage" [ngClass]="{ active: activeStage >= 3 }">Infringement Analysis</div>
    </div>
    <div class="progress-bar-background">
        <div class="progress-bar" [ngStyle]="{ 'width.%': percentageCompletion }"></div>
    </div>
</div>