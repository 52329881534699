import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScoreLegendComponent } from "../common/score-legend/score-legend.component";
import { ValidityScoreComponent } from "../common/validity-score/validity-score.component";
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import { DataService } from '../services/data.service';
import { PopoverClose, PopoverComponent, PopoverModule, PopoverTemplate } from '@ngx-popovers/popover';
import { TruncateTextWidthDirective } from '../common/directives/truncate.directive';

declare var html2pdf: any;

@Component({
  selector: 'app-sniff-report',
  standalone: true,
  imports: [CommonModule, ScoreLegendComponent, ValidityScoreComponent, PopoverClose, PopoverComponent, PopoverTemplate, PopoverModule, TruncateTextWidthDirective],
  templateUrl: './sniff-report.component.html',
  styleUrl: './sniff-report.component.css'
})
export class SniffReportComponent {

  
  @Input () inputData:any=[];
  @Input () validitySkip:boolean=false;
  @Input () infringementData:any=[];
  @Input () validityData:any=[];
  @Input () industryData:any=[];

  @Input() currentReportMode:any=null;
  
  @Input() onClickCancel:any=null;
  

  exportUI:boolean=false;
  exportingState=false;
  
  constructor(private dataService:DataService) { 
    console.log('sniff report initiated')
    if(this.currentReportMode==null)
    {
      this.currentReportMode=this.dataService.activeReport;
    }
    this.inputData=this.dataService.inputData;
    this.validityData=this.dataService.pasData;
    this.industryData=this.dataService.industryData;
    this.infringementData=this.dataService.infringementData;
    this.validitySkip=this.dataService.skipPas;
    if(this.currentReportMode=='infringement'){
      this.validitySkip=true;
    }
    else {
      this.validitySkip=false;
    }
    console.log('skip pas:', this.validitySkip, this.dataService.skipPas)
  }
  currentTab="industry";

  @Input () selectedPatentIndex:any=null;
  infringementArray:any[]=[];
  claimChartData:any[]=[];
  isEditingCompanies=false;

  ngOnInit(){
    // this.validitySkip=this.dataService.skipPas;
    this.inputData=this.dataService.inputData;
    this.validityData=this.dataService.pasData;
    this.industryData=this.dataService.industryData;
    this.infringementData=this.dataService.infringementData;
    console.log('inputdata', this.inputData)
    console.log('infringement data',this.infringementData)
    console.log('industry data', this.industryData)
    console.log(this.validityData.referenceTableMap)
    this.infringementArray=this.infringementData.patentDetails;
    this.claimChartData=this.infringementData.claimChartData;

    if(this.currentReportMode=='infringement'){
      this.currentTab='industry';
      this.validitySkip=true;
    }
    else{
      this.currentTab='pas';
    }

  }

  getFilteredClaimChartData(patentNumber:string){
    return this.claimChartData.filter((item:any)=>item.patent_number==patentNumber)
  }
  getFilteredIndustryData(patentNumber:string){
    return this.industryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getSummaryData(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getValidityScoreCount(){
    if(this.validitySkip)
      return 0;
    return this.validityData.summaryData.filter((x:any)=>x.score>7).length
  }

  getValidityScore(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)[0].score
  }
  referenceTableOpenMapObj:any={};
  toggleReferenceTable(patentNumber:any, referenceNumber:any){
    if(!this.referenceTableOpenMapObj[patentNumber]){
      this.referenceTableOpenMapObj[patentNumber]={};
      this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
  }

  referenceTableOpenMap(patentNumber:any, referenceNumber:any, index:any){
    // map based on patentNumber and further referenceNumber. If the reference Object does not exist, create a new object with the referenceNumber as key and value as false
    if(!this.referenceTableOpenMapObj[patentNumber]){
      if(index==0&&this.referenceTableOpenMapObj[patentNumber]===undefined){
        this.referenceTableOpenMapObj[patentNumber]={};
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else
      this.referenceTableOpenMapObj[patentNumber]={};
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
    return this.referenceTableOpenMapObj[patentNumber][referenceNumber];
  }

  getScoreValue(){
    if(this.selectedPatentIndex!=null){
      if(this.currentTab=='pas')
        return this.getValidityScore(this.inputData[this.selectedPatentIndex].patentNumber)

      return this.infringementData['patentInfringementScoreData'][this.inputData[this.selectedPatentIndex].patentNumber]
    }
    return 0;
  }

  setSelectedPatentIndex(patentNumber:string){
    console.log(patentNumber)
    let index=this.inputData.findIndex((item:any)=>item.patentNumber==patentNumber);
    this.selectedPatentIndex=index;
    console.log(index, this.inputData[index])
  }

  exportPDF(){
    this.exportingState=true;
    this.exportUI=true;
    setTimeout(() => {
    var element:any = document.getElementById('exportable-ui');
    
    if(this.selectedPatentIndex!=null){
      element=document.getElementById('major-report');
      console.log(element)
    }
    element.classList.add('min-height');
    let opt={
      filename:'sniff-report.pdf',
      width: 2500,
      height:100,

    }
      
    //      //$("#previewBeforeDownload").html(canvas);
    //      var imgData = canvas.toDataURL("image/jpeg",1);
    //      var pdf = new jsPDF("p", "px", "a2");
    //      // var pageWidth = pdf.internal.pageSize.getWidth();
    //      var pageWidth=pdf.internal.pageSize.getWidth();
    //      var pageHeight = pdf.internal.pageSize.getHeight();
    //      var imageWidth = canvas.width;
    //      var imageHeight = canvas.height;
   
    //      var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
    //      //pdf = new jsPDF(this.state.orientation, undefined, format);
    //      pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
    //      pdf.save("sniff-report.pdf");
    //  });
   // Get the dimensions of the HTML element
const elementHeight = element.offsetHeight;
const elementWidth = element.offsetWidth;

// Estimate the timeout based on the element's height (e.g., 1ms per pixel)
const estimatedTimeout = elementHeight * 1; // Adjust the multiplier as necessary

// html2canvas(element).then(canvas => {
//   var imgData = canvas.toDataURL("image/jpeg", 1);
  
//   // Get the dimensions of the canvas
//   var imageWidth = canvas.width;
//   var imageHeight = canvas.height;

//   // Calculate the required PDF dimensions
//   var pdfWidth = imageWidth;
//   var pdfHeight = imageHeight;
//   console.log('canvas details', canvas.width, canvas.height)

//   // Create the PDF with the calculated dimensions
//   var pdf = new jsPDF("p", "px", [pdfWidth, pdfHeight]);

//   // Add the image to the PDF
//   pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

//   // Save the PDF
//   pdf.save("sniff-report.pdf");
// }).finally(() => {
//   // setTimeout(() => {
//     this.exportingState = false;
//     this.exportUI = false;
//     element.classList.remove('min-height');
//   // }, estimatedTimeout); // Use the estimated timeout
// });
html2canvas(element).then(canvas => {
  // Get the dimensions of the canvas
  var imageWidth = canvas.width;
  var imageHeight = canvas.height;
  console.log('canvas details', imageWidth, imageHeight);

  // Define the maximum height for each PDF page.
  // You can adjust this value as needed.
  var pageHeight = 1500;
  var totalPages = Math.ceil(imageHeight / pageHeight);

  // Process the first slice separately to create the initial PDF page
  var firstSliceHeight = Math.min(pageHeight, imageHeight);
  var canvasSlice = document.createElement('canvas');
  canvasSlice.width = imageWidth;
  canvasSlice.height = firstSliceHeight;
  var ctx:any = canvasSlice.getContext('2d');
  ctx.drawImage(canvas, 0, 0, imageWidth, firstSliceHeight, 0, 0, imageWidth, firstSliceHeight);
  var sliceData = canvasSlice.toDataURL("image/jpeg", 1);

  // Create the PDF with the first page dimensions matching the first slice
  var pdf = new jsPDF("p", "px", [imageWidth, firstSliceHeight]);
  pdf.addImage(sliceData, 'JPEG', 0, 0, imageWidth, firstSliceHeight);

  // Process remaining slices and add each as a new page in the PDF
  for (var i = 1; i < totalPages; i++) {
    var y = i * pageHeight;
    var sliceHeight = Math.min(pageHeight, imageHeight - y);

    var canvasSlice = document.createElement('canvas');
    canvasSlice.width = imageWidth;
    canvasSlice.height = sliceHeight;
    var ctx:any = canvasSlice.getContext('2d');
    ctx.drawImage(canvas, 0, y, imageWidth, sliceHeight, 0, 0, imageWidth, sliceHeight);
    var sliceData = canvasSlice.toDataURL("image/jpeg", 1);

    // Add a new page with the slice height
    pdf.addPage([imageWidth, sliceHeight]);
    pdf.addImage(sliceData, 'JPEG', 0, 0, imageWidth, sliceHeight);
  }

  // Save the final PDF with all pages
  pdf.save("sniff-report.pdf");
}).finally(() => {
  this.exportingState = false;
  this.exportUI = false;
  element.classList.remove('min-height');
});

    }, 10);
    }

    calculateInfringedCompanies(){
        
    }

    calculateRevenue(){
      
    }

    changeIndex(index:number){
      if(this.selectedPatentIndex+index>=0 && this.selectedPatentIndex+index<this.inputData.length){
        this.selectedPatentIndex+=index;
      }
    }

    getRevenueDetails(){
      let revenueArray:any=[];
      this.industryData.forEach((element:any) => {
        let dataArray=element.companies;
      for(let data of dataArray){
        let companyData={
          name:data.name,
          products:[]
        }
        for(let product of data.products){
          revenueArray.push(product.revenue.response)
        }
      }
    });

      console.log(revenueArray)
      let revenueString=this.sumCurrencies(revenueArray);
      console.log(revenueString)
      return revenueString;


    }

     sumCurrencies(values:any) {
        const denominations:any = {
            billion: 1e9,
            million: 1e6
        };
    
        let total = 0;
    
        for (let value of values) {
            value = value.split(/[\(\[]/)[0];
            const match = value.match(/\$?([\d.,]+ (million|billion|trillion)?)\s*(-\s*(\$?\s*[\d.,]+))?\s*(million|billion|trillion)?/i);
            if (!match) continue;
            console.log(match)
            const baseValue = parseFloat(match[1]);
            match[4] = match[4] ? match[4].replace('$', '') : null;
            const rangeEnd = match[4] ? parseFloat(match[4]) : null;
            let unit_candidate=match[5];
            
            if(!unit_candidate){
                unit_candidate=match[2];
            }
            let unit = unit_candidate ? unit_candidate.toLowerCase() : "million";
            unit = [unit, unit];
            if(unit_candidate&&match[2]&&match[2]!=match[5]){
              let correctUnit=match[2].toLowerCase();
              let candidateUnit=unit_candidate.toLowerCase();
              if(Object.keys(denominations).includes(candidateUnit)&&Object.keys(denominations).includes(correctUnit)){
              unit=[correctUnit, unit[1]];
              
              }
            }
    
            if (rangeEnd && !value.includes('- $')) {
                total += (baseValue * denominations[unit[0]] + rangeEnd * denominations[unit[1]]) / 2;
            } else {
                const finalValue = rangeEnd
                    ? (baseValue * denominations[unit[0]] + rangeEnd * denominations[unit[1]]) / 2
                    : baseValue * denominations[unit[0]];
    
                total += finalValue;
            }
        }
    
        if (total >= 1e9) {
            const totalInBillions = total / denominations.billion;
            const truncated = Math.floor(totalInBillions * 10) / 10;
            const hasExtra = (totalInBillions * 100) % 10 > 0;
            return hasExtra
                ? `$${truncated.toFixed(1)}+ B`
                : `$${truncated.toFixed(1)} B`;
        } else {
            const totalInMillions = total / denominations.million;
            const truncated = Math.floor(totalInMillions * 10) / 10;
            const hasExtra = (totalInMillions * 100) % 10 > 0;
            return hasExtra
                ? `$${truncated.toFixed(1)}+ M`
                : `$${truncated.toFixed(1)} M`;
        }
    
        return '$0';
    }
    getLinkOrAiEstimate(str:any){
      if(!str){
        return "Unknown";
      }
      if(str.includes('http')){
        return `<a href=${str} target="_blank">${str}</a>`
      }
      else if(str=='Unknown')
      {
        return "Unknown";
      }
      else{
        let target = str.split('(')[1];
        if(!target){
          return str;
        }
        target = target.slice(0, -1);
        return `<h6>AI Estimate</h6> <p>${target}</p>`
      }
    }
    getRevenueSumDetails(patent:any) {
      let revenueArray: any[] = [];
      
      // Iterate over the passed products array to collect revenue responses.
      patent.companies.forEach((company: any) => {
        company.products.forEach((product: any) => {
        revenueArray.push(product.revenue.response);
      });});
    
      console.log(revenueArray);
      const revenueRange = this.sumCurrenciesRange(revenueArray);
      console.log(revenueRange);
      return revenueRange;
    }
    
    sumCurrenciesRange(values: any) {
      const denominations: any = {
        billion: 1e9,
        million: 1e6
      };
    
      let totalMin = 0;
      let totalMax = 0;
    
      for (let value of values) {
        // Remove any extra comments or text after '(' or '['.
        value = value.split(/[\(\[]/)[0];
        const match = value.match(
          /\$?([\d.,]+ (million|billion|trillion)?)\s*(-\s*(\$?\s*[\d.,]+))?\s*(million|billion|trillion)?/i
        );
        if (!match) continue;
        console.log(match);
    
        // Parse the first number.
        const baseValue = parseFloat(match[1]);
        // Clean and parse the second number if it exists.
        match[4] = match[4] ? match[4].replace('$', '') : null;
        const rangeEnd = match[4] ? parseFloat(match[4]) : null;
    
        // Determine the unit: prefer group 5, then group 2.
        let unit_candidate = match[5] || match[2];
        let unit = unit_candidate ? unit_candidate.toLowerCase() : "million";
        // Use an array to hold lower and upper units.
        unit = [unit, unit];
        if (match[5] && match[2] && match[2] !== match[5]) {
          let correctUnit = match[2].toLowerCase();
          let candidateUnit = unit_candidate.toLowerCase();
          if (
            Object.keys(denominations).includes(candidateUnit) &&
            Object.keys(denominations).includes(correctUnit)
          ) {
            unit = [correctUnit, unit[1]];
          }
        }
    
        // Calculate totals based on whether a range is provided.
        if (rangeEnd) {
          totalMin += baseValue * denominations[unit[0]];
          totalMax += rangeEnd * denominations[unit[1]];
        } else {
          const finalValue = baseValue * denominations[unit[0]];
          totalMin += finalValue;
          totalMax += finalValue;
        }
      }
    
      // Format the range string based on the upper bound.
      if (totalMax >= 1e9) {
        const minInBillions = totalMin / denominations.billion;
        const maxInBillions = totalMax / denominations.billion;
        const truncatedMin = Math.floor(minInBillions * 10) / 10;
        const truncatedMax = Math.floor(maxInBillions * 10) / 10;
        return `$${truncatedMin.toFixed(1)} B - $${truncatedMax.toFixed(1)} B`;
      } else {
        const minInMillions = totalMin / denominations.million;
        const maxInMillions = totalMax / denominations.million;
        const truncatedMin = Math.floor(minInMillions * 10) / 10;
        const truncatedMax = Math.floor(maxInMillions * 10) / 10;
        return `$${truncatedMin.toFixed(1)} M - $${truncatedMax.toFixed(1)} M`;
      }
    }
    
/**
 * getInvalidityStats()
 * Computes summary statistics from the summaryData array.
 * It calculates the total number of patents, and counts how many fall into low (score 1–3),
 * medium (score 4–6), and high (score 7–10) invalidity ranges.
 */
getInvalidityStats() {
  let total = this.validityData.summaryData.length;
  let lowCount = 0;   // Score 1-3
  let midCount = 0;   // Score 4-6
  let highCount = 0;  // Score 7-10

  this.validityData.summaryData.forEach((p: any) => {
    const s = p.score || 0;
    if (s > 0 && s <= 3) lowCount++;
    else if (s >= 4 && s <= 6) midCount++;
    else if (s >= 7) highCount++;
  });

  return { total, lowCount, midCount, highCount };
}

getInfringementStats(){

  let total = this.infringementData['patentInfringementScoreData'].length;
  let lowCount = 0;   // Score 1-3
  let midCount = 0;   // Score 4-6
  let highCount = 0;  // Score 7–10

  Object.keys(this.infringementData['patentInfringementScoreData']).forEach((patentNumber: any) => {
    const s = this.infringementData['patentInfringementScoreData'][patentNumber] || 0;
    if (s > 0 && s <= 3) lowCount++;
    else if (s >= 4 && s <= 6) midCount++;
    else if (s >= 7) highCount++;
  });

  return { total, lowCount, midCount, highCount };
}

get infringeOrFullReport(){
  if(this.currentReportMode=='infringement'||this.currentReportMode=='full'){
    return true
  }
  return false;
}

referenceDivOpenMapObj:any={};

referenceDivOpenMap(patentNumber:any, index:any){

  if(this.exportingState){
    return true;
  }

  if(!this.referenceDivOpenMapObj[patentNumber]){
    if(index==0&&this.referenceDivOpenMapObj[patentNumber]===undefined){
      this.referenceDivOpenMapObj[patentNumber]=true;
    }
    else
    this.referenceDivOpenMapObj[patentNumber]=false;
  }
  else{
    this.referenceDivOpenMapObj[patentNumber]=true;
  }
  return this.referenceDivOpenMapObj[patentNumber];
}

toggleReferenceDiv(patentNumber:any){
  this.referenceDivOpenMapObj[patentNumber]=!this.referenceDivOpenMapObj[patentNumber];
  console.log('referenceDivOpenMapObj:', this.referenceDivOpenMapObj)
}

}