<div class="progress-container">
    <div class="stage-container">

        <div class="stage" [ngClass]="{ active: activeStage >= 1 }">Target Scouting </div>
        <div class="stage" [ngClass]="{ active: activeStage >= 2 }">Infringement Analysis</div>
        <div class="stage" [ngClass]="{ active: activeStage >= 3 }">Damage Analysis</div>
        <div class="stage" [ngClass]="{ active: activeStage >= 4 }">Summary</div>
    </div>
    <div class="progress-bar-background">
        <div class="progress-bar" [ngStyle]="{ 'width.%': percentageCompletion }"></div>
    </div>
</div>