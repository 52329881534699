import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-edit-links-dialog',
  template: `
    <h2 mat-dialog-title>Links

    <span (click)="toggleEdit()" > <img class="edit-icon" src="/assets/images/edit-link-icon.png" > </span>
    <label class="file-upload-label" *ngIf="!isUploading"> <img class="edit-icon" src="/assets/images/upload-link-icon.png" > 
      <input type="file" (change)="uploadFile($event)" [disabled]="isUploading" />
    </label>
    </h2>
    <div mat-dialog-content>
      <div class="">
        <label class="file-upload-label">
          <span *ngIf="isUploading">Uploading...</span>
        </label>
      </div>
      <ul class="links-list">
        <li *ngFor="let link of links;let i=index">
          {{i+1}}. <a [href]="link" target="_blank">{{ link }}</a>
          <button class="borderless-button"  *ngIf="editMode&&loadingIndex!=i" mat-icon-button (click)="deleteLink(link)">
                <img src="assets/images/minus-circle.png" alt="delete" />
          </button>
    <mat-spinner *ngIf="loadingIndex==i" [color]="'primary'" [diameter]="26"></mat-spinner>

        </li>
      </ul>
      <div *ngIf="editMode"  class="add-link-container">
        <input matInput [(ngModel)]="newLink" placeholder="Add new link" />
        <button *ngIf="!loadingAdd" class="borderless-button" mat-icon-button (click)="addLink()">
                <img class="plus-button" src="assets/images/plus-circle.png" alt="add" />
        </button>
    <mat-spinner *ngIf="loadingAdd" [color]="'primary'" [diameter]="26"></mat-spinner>
        
      </div>
    </div>
    <div class="dialog-footer" mat-dialog-actions>
      <!-- <button mat-button (click)="dialogRef.close()">Cancel</button> -->
      <button class="btn btn-primary" mat-raised-button color="primary" (click)="saveChanges()">Save</button>
    </div>
  `,
  styleUrls: ['./edit-links-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, MatIconModule, FormsModule, MatProgressSpinner]
})
export class EditLinksDialogComponent {
  links: string[] = [];
  newLink: string = '';
  isUploading: boolean = false;
  editMode=false;

  toggleEdit(){
    this.editMode=!this.editMode
  }

  loadingIndex:any=null;
  loadingAdd=false;  


  constructor(
    public dialogRef: MatDialogRef<EditLinksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpService,
    private dialog: MatDialog
  ) {
    this.links = [...data.links];
  }

  addLink() {
    if (this.newLink.trim()) {
      this.updateLinks('ADD', this.newLink);
      this.newLink = '';
    }
  }

  deleteLink(link: string) {
    this.updateLinks('DELETE', link);
  }

  updateLinks(operation: 'ADD' | 'DELETE', link: string) {
    const requestData = {
      operation,
      targeted_product: this.data.targetedProduct,
      link
    };

    if(operation=='ADD'){
      this.loadingAdd=true
    }
    else{
      this.loadingIndex=this.links.indexOf(link)
    }

    this.http.post('/infringement-assessment/technical-documentation/update', { data: requestData })
      .then(() => {
        console.log('link ', link)
        if (operation === 'ADD') this.links.push(link);
        else this.links = this.links.filter(l => l !== link);
        console.log('link ', this.links)
        this.loadingIndex=null;
        this.loadingAdd=false;
        // this.regenerateClaimChart();
      })
      .catch(error => {console.error('Error updating link:', error);
      this.loadingIndex=null;
      this.loadingAdd=false;

      });
  }

  uploadFile(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    this.isUploading = true;
    const formData = new FormData();
    formData.append('file', file);

    this.http.postFormData('/infringement-assessment/upload-user-technical-documentation', formData)
      .then(response => {
        this.updateLinks('ADD', response.user_technical_documentation_link);
        this.isUploading = false;
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        this.isUploading = false;
      });
  }

  regenerateClaimChart() {
    this.dialogRef.close(this.links);
    // Call regenerate claim chart API
    console.log("Regenerating claim chart for:", this.data.targetedProduct);
  }

  saveChanges() {
    this.dialogRef.close(this.links);
  }
}

export class InfringementAssessmentComponent {
  constructor(private dialog: MatDialog, private http: HttpService) {}

  openEditLinksDialog(targetedProduct: string, targetedCompany: string, links: string[]) {
    const dialogRef = this.dialog.open(EditLinksDialogComponent, {
      width: '500px',
      data: { targetedProduct, targetedCompany, links }
    });

    dialogRef.afterClosed().subscribe(updatedLinks => {
      if (updatedLinks) {
        console.log('Updated links:', updatedLinks);
      }
    });
  }
}
