import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { UploadComponent } from './upload/upload.component';
import { IdWrapperComponent } from './id-wrapper/id-wrapper.component';

export const routes: Routes = [
    
            // {
            //     path:'',
            //     redirectTo:'upload',
            //     pathMatch:'full'
            // },
            // {
            //     path:'upload',
            //     component:UploadComponent,
            // },
            // {
            //     path:'report',
            //     component:IdWrapperComponent
            // },
            {
                path:'app',
                component:IdWrapperComponent,
                children:[
                    {
                        path:'upload',
                        component:UploadComponent
                    },
                    {
                        path:'home', 
                        component:IdWrapperComponent
                    },
                    {
                        path:'report',
                        component:IdWrapperComponent
                    },
                    {
                        path:'validity',
                        component:IdWrapperComponent
                    },
                    {
                        path:'damage',
                        component:IdWrapperComponent
                    },
                    {
                        path:'infringement',
                        component:IdWrapperComponent
                    }
                ]
            },
            {
                path:'',
                redirectTo:'app',
                pathMatch:'full'
            }
];
