<div class="popup-container">
    <h2>Upload a document here</h2>
    <div class="file-upload-box" *ngIf="!selectedFile" (click)="fileInput.click()"></div>
    <input type="file" #fileInput (change)="onFileSelected($event)" hidden />
    <mat-icon>cloud_upload</mat-icon>
    <p>Click to upload a file.</p>
    <div class="file-selected-box" *ngIf="selectedFile">
        <p>{{ selectedFile.name }}</p>
        <mat-icon class="remove-icon" (click)="clearFile()">close</mat-icon>
    </div>
    <div class="button-group">
        <button mat-button color="warn" (click)="close()">Cancel</button>
        <button mat-button color="primary" [disabled]="!selectedFile" (click)="uploadFile()">Upload</button>
    </div>
</div>