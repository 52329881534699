<div class="dashboard-screen">
    <!-- Heading -->
    <div class="sniff-report-heading-container">
        <div class="sniff-report-heading">Sniff Test Report</div>
        <div class="sniff-report-controls">
            <button class="export-button" (click)="exportPDF()">Export</button>
            <button class="back-button"><svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1044_8739)">
            <path d="M36.8262 37.1449C42.909 31.1216 42.9572 21.3077 36.9339 15.2249C30.9106 9.14211 21.0966 9.09392 15.0139 15.1172C8.93107 21.1406 8.88287 30.9545 14.9062 37.0373C20.9295 43.1201 30.7435 43.1683 36.8262 37.1449Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 22L30.9567 30.9567" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 30.957L30.9567 22.0003" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1044_8739">
            <rect width="38" height="38" fill="white" transform="translate(0 27) rotate(-45)"/>
            </clipPath>
            </defs>
            </svg>
            </button>
        </div>
    </div>
    <div class="sniff-tab-bar">
        <!-- <div class="sniff-tab " [class.sniff-active-tab]="currentTab=='industry'" (click)="currentTab='industry'">Overview and Competitor Landscape</div> -->
        <!-- <div class="sniff-tab" *ngIf="!validitySkip" [class.sniff-active-tab]="currentTab=='pas'" (click)="currentTab='pas'">Invalidity</div> -->
        <div class="sniff-tab" [class.sniff-active-tab]="currentTab=='infringement'" (click)="currentTab='infringement'">Infringe Fusion</div>

    </div>
    <!-- Widgets Row -->
    <div class="industry-report-widgets">
        <div class="industry-report-widget widget-blue">
            <div class="widget-heading">Total Patents</div>
            <div class="widget-main-text"></div>
            <div class="widget-sub-text">US Patents</div>
        </div>
        <div class="industry-report-widget widget-red">
            <div class="widget-heading">Low Invalidity Level</div>
            <div class="widget-main-text"></div>
            <div class="widget-sub-text">Patent within score range 1-3</div>
        </div>
        <div class="industry-report-widget widget-yellow">
            <div class="widget-heading">Medium Invalidity Level</div>
            <div class="widget-main-text"></div>
            <div class="widget-sub-text">Patent within score range 4-6</div>
        </div>
        <div class="industry-report-widget widget-green">
            <div class="widget-heading">High Invalidity Level</div>
            <div class="widget-main-text"></div>
            <div class="widget-sub-text">Patent within score range 7-10</div>
        </div>
    </div>

    <!-- Dashboard Table -->
    <div class="industry-table-container">
        <table class="industry-table">
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Title</th>
                    <th>Priority Date</th>
                    <th>Current Assignee</th>
                    <th>Invalidity Score</th>
                    <th>Damage Value</th>
                    <th>Detailed Report</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of inputData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <a [href]="'https://patents.google.com/patent/' + patent.patentNumber" target="_blank">
                {{ patent.patentNumber }}
              </a>
                    </td>
                    <td>{{ patent.title }}</td>
                    <td>{{ patent.priorityDate || 'N/A' }}</td>
                    <td>{{ patent.assignee }}</td>
                    <td>
                        <!-- <app-validity-score [score]="patentInfringementScoreData[patent.patentNumber]"></app-validity-score> -->
                        <app-validity-score [score]="infringementData['patentInfringementScoreData'][patent.patentNumber]"></app-validity-score>
                        <!-- </ng-container> -->
                    </td>
                    <td>
                        <span>{{getRevenueSumDetails(patent)}}</span>
                    </td>
                    <td>
                        <a class="view-report-link" (click)="onViewReport(i)">View Report</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>