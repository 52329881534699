import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validity-score',
  standalone: true,
  imports: [],
  templateUrl: './validity-score.component.html',
  styleUrl: './validity-score.component.css'
})
export class ValidityScoreComponent {
@Input () score:any=0;

@Input () infringementScore=false;
}
