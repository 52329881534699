import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  standalone: true,
  selector: 'app-link-input-popup',
  templateUrl: './link-input-popup.component.html',
  styleUrls: ['./link-input-popup.component.css'],
  imports: [CommonModule, FormsModule]
})
export class LinkInputPopupComponent {
  inputValue: string = '';

  constructor(
    public dialogRef: MatDialogRef<LinkInputPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.inputValue) {
      this.inputValue = data.inputValue;
    }
  }

  submitValue(): void {
    this.dialogRef.close(this.inputValue);
  }

  close(): void {
    this.dialogRef.close();
  }
}
