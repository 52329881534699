import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { UploadComponent } from './upload/upload.component';
import { IdWrapperComponent } from './id-wrapper/id-wrapper.component';
import { SniffReportComponent } from './sniff-report/sniff-report.component';
import { PriorArtSearchComponent } from './prior-art-search/prior-art-search.component';
import { IndustryAnalysisComponent } from './industry-analysis/industry-analysis.component';
import { InfringementAnalysisComponent } from './infringement-analysis/infringement-analysis.component';
import { PostUploadComponent } from './post-upload/post-upload.component';
import { PromptManagementComponent } from './prompt-management/prompt-management.component';
import { TargetScoutingComponent } from './target-scouting/target-scouting.component';
import { InfringementSummaryComponent } from './infringement-summary/infringement-summary.component';

export const routes: Routes = [
    
            // {
            //     path:'',
            //     redirectTo:'upload',
            //     pathMatch:'full'
            // },
            // {
            //     path:'upload',
            //     component:UploadComponent,
            // },
            // {
            //     path:'report',
            //     component:IdWrapperComponent
            // },
            {
                path:'app',
                component:IdWrapperComponent,
                children:[
                    {
                        path:'',
                        redirectTo:'upload',
                        pathMatch:'full'
                    },
                    {
                        path:'upload',
                        component:UploadComponent,
                        // make this the default route
                        pathMatch:'full'
                    },
                    {
                        path:'home', 
                        component:IdWrapperComponent
                    },
                    {
                        path:'report',
                        component:SniffReportComponent
                    },
                    {
                        path:'pas',
                        component:PriorArtSearchComponent
                    },
                    {
                        path:'target-scouting',
                        component:TargetScoutingComponent
                    },
                    {
                        path:'industry-analysis',
                        component:IndustryAnalysisComponent
                    },
                    {
                        path:'infringement-analysis',
                        component:InfringementAnalysisComponent
                    },
                    {
                        path:'infringement-summary',
                        component:InfringementSummaryComponent
                    },
                    {
                        path:'post-upload',
                        component:PostUploadComponent
                    },
            {
                path:'prompt-management',
                component:PromptManagementComponent,
                pathMatch:'full'
            }
                ]
            },
            {
                path:'',
                redirectTo:'app/upload',
                pathMatch:'full'
            }
];
