import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

export interface ForceLoginDialogData {
  onForceLogin: () => void;
}

@Component({
  selector: 'app-force-login-popup',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './force-login-popup.component.html',
  styleUrls: ['./force-login-popup.component.css'],
})
export class ForceLoginPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ForceLoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForceLoginDialogData
  ) {}

  forceLogin(): void {
    this.data.onForceLogin();
    
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
