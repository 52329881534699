<ng-container *ngIf="!exportUI">

    <div class="sniff-report-heading-container">
        <div class="sniff-report-heading">
            Sniff Test Report
        </div>
        <div class="sniff-report-controls">
            <button class="export-button" (click)="exportPDF()">Export</button>
            <button class="back-button"><svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1044_8739)">
            <path d="M36.8262 37.1449C42.909 31.1216 42.9572 21.3077 36.9339 15.2249C30.9106 9.14211 21.0966 9.09392 15.0139 15.1172C8.93107 21.1406 8.88287 30.9545 14.9062 37.0373C20.9295 43.1201 30.7435 43.1683 36.8262 37.1449Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 22L30.9567 30.9567" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 30.957L30.9567 22.0003" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1044_8739">
            <rect width="38" height="38" fill="white" transform="translate(0 27) rotate(-45)"/>
            </clipPath>
            </defs>
            </svg>
            </button>
        </div>
    </div>

    <div class="sniff-tab-bar">
        <div class="sniff-tab " [class.sniff-active-tab]="currentTab=='industry'" (click)="currentTab='industry'">Overview and Competitor Landscape</div>
        <div class="sniff-tab" *ngIf="!validitySkip" [class.sniff-active-tab]="currentTab=='pas'" (click)="currentTab='pas'">Validity</div>
        <div class="sniff-tab" [class.sniff-active-tab]="currentTab=='infringement'" (click)="currentTab='infringement'">Infringement Assessment</div>

    </div>

    <div class="sniff-tab-content-wrapper" *ngIf="selectedPatentIndex==null">
        <div *ngIf="currentTab=='industry'" class="sniff-tab-content industry-report">
            <div class="industry-report-widgets">
                <div class="industry-report-widget widget-blue">
                    <div class="widget-heading">
                        Patents
                    </div>
                    <div class="widget-main-text">
                        {{industryData.length}}
                    </div>
                    <div class="widget-sub-text">
                        US Patents
                    </div>
                </div>
                <div class="industry-report-widget widget-red" *ngIf="!validitySkip">
                    <div class="widget-heading">
                        Validity
                    </div>
                    <div class="widget-main-text">
                        {{getValidityScoreCount()}}
                    </div>
                    <div class="widget-sub-text">
                        Patents with more than 7 Validity Score
                    </div>
                </div>
                <!-- <div class="industry-report-widget widget-yellow">
                    <div class="widget-heading">
                        Damage
                    </div>
                    <div class="widget-main-text">
                        $12B
                    </div>
                    <div class="widget-sub-text">
                        Estimated Revenue
                    </div>
                </div> -->
                <div class="industry-report-widget widget-green" *ngIf="!validitySkip">
                    <div class="widget-heading">
                        Infringement Companies
                    </div>
                    <div class="widget-main-text">
                        {{infringementArray.length}}
                    </div>
                    <div class="widget-sub-text">
                        No. of infringed companies
                    </div>
                </div>
            </div>
            <div class="industry-table">
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Current Assignee</th>
                            <th>Targeted Company</th>
                            <th>Company Revenue</th>
                            <th>Targeted Product</th>
                            <th>Product Revenue</th>
                            <th>Global Headquarters</th>
                            <th>US Headquarters</th>
                            <th>State of Incorporation</th>
                            <th>Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of industryData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>{{patent.currentAssignee}}</td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <div>
                                        {{ company.name }}
                                    </div>
                                    <ng-container *ngFor="let product of company.products;let k= index">
                                        <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">

                                    <div class="revenue-edit-controls">

                                        <div>
                                            {{ company.companyInfo.Revenue.response }}
                                            <!-- <button mat-icon-button (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                        <mat-icon>edit</mat-icon>
                        </button> -->
                                        </div>

                                    </div>


                                    <ng-container *ngFor="let product of company.products;let k= index">
                                        <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <ng-container *ngFor="let product of company.products;let ind=index">

                                        <div [class.last-item]="ind==company.products.length-1">
                                            {{ product.name }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">

                                        <div [class.last-item]="ind==company.products.length-1">
                                            {{ product.revenue.response }}
                                            <!-- <ng-container *ngIf="product.revenue.response">
                                            {{product.revenue?.response?.length()}}
                                        </ng-container> -->
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies; let j=index">
                                    <ng-container *ngIf="company.new!=true">
                                        <div>

                                            {{ company.companyInfo["Global Headquarters"]["response"] }}
                                        </div>
                                        <ng-container *ngFor="let product of company.products;let i= index">
                                            <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies; let j=index">
                                    <ng-container *ngIf="company.new!=true">
                                        <div>

                                            {{ company.companyInfo["US Headquarters"]["response"] }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let product of company.products;let i= index">
                                        <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <ng-container *ngIf="company.new!=true">
                                        <div>

                                            {{ company.companyInfo["State of Incorporation"]["response"] }}
                                        </div>
                                        <ng-container *ngFor="let product of company.products;let i= index">
                                            <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                            </td>
                            <td>
                                <button class="btn-primary report-button" (click)="setSelectedPatentIndex(patent.patentNumber)">View Report</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div *ngIf="currentTab=='pas'" class="sniff-tab-content pas-report">
            <div class="pas-table">
                <div class="legend-flex-end">
                    <app-score-legend></app-score-legend>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Title</th>
                            <th>Current Assignee</th>
                            <th>Validity Score</th>
                            <th>Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of validityData.summaryData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>{{patent.title}}</td>
                            <td>{{patent.assignee}}</td>
                            <td>
                                <app-validity-score [score]="patent.score"></app-validity-score>
                            </td>
                            <td>
                                <button class="btn-primary btn-report" (click)="setSelectedPatentIndex(patent.patentNumber)">View Report</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div *ngIf="currentTab=='infringement'" class="sniff-tab-content infringement-report">
            <div class="infringement-table">
                <div class="legend-flex-end">
                    <app-score-legend></app-score-legend>
                </div>
                <!-- <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Title</th>
                            <th>Priority Date</th>
                            <th>Current Assignee</th>
                            <th>Validity Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of validityData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>Automated cinematic decisions based on descriptive models</td>
                            <td>2019-01-01</td>
                            <td>Cilag GmbH International</td>
                            <td>8</td>
                        </tr>
                    </tbody>
                </table> -->
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Targeted Company</th>
                            <th>Targeted Product</th>
                            <th>Infringement Score</th>
                            <th>Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of infringementArray; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <div>
                                        {{ company.name }}
                                    </div>
                                    <ng-container *ngFor="let product of company.products;let k= index">
                                        <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>

                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <ng-container *ngFor="let product of company.products;let ind=index">

                                        <div [class.last-item]="ind==company.products.length-1">
                                            {{ product.name }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <app-validity-score [score]="infringementData['patentInfringementScoreData'][patent.patentNumber]"></app-validity-score>
                            </td>
                            <td>
                                <button class="btn-report btn-primary report-button" (click)="setSelectedPatentIndex(patent.patentNumber)">View Report</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</ng-container>
<!-- <ng-container *ngIf="!exportUI&&!selectedPatentIndex"> -->

<div class="major-report-wrapper" id="major-report" *ngIf="selectedPatentIndex!=null">
    <div class="major-report-header">
        <button class="back-button" (click)="selectedPatentIndex=null">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.0026 25.6654C20.4459 25.6654 25.6693 20.442 25.6693 13.9987C25.6693 7.55538 20.4459 2.33203 14.0026 2.33203C7.55928 2.33203 2.33594 7.55538 2.33594 13.9987C2.33594 20.442 7.55928 25.6654 14.0026 25.6654Z" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.0026 9.33203L9.33594 13.9987L14.0026 18.6654" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.6693 14H9.33594" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </button>
        <div class="patent-title-and-controls">
            <div class="left-button" (click)="changeIndex(-1)">
                <svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-4.5897e-07 9.5L9 0.406734L9 18.5933L-4.5897e-07 9.5Z" fill="black"/>
                        </svg>

            </div>
            <div class="patent-title">
                {{inputData[selectedPatentIndex].patentNumber}}
            </div>
            <div class="right-button" (click)="changeIndex(1)">
                <svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 9.5L-8.56449e-07 18.5933L-6.14903e-08 0.406733L9 9.5Z" fill="black"/>
                        </svg>
            </div>
        </div>
        <div class="patent-index-information">
            ({{selectedPatentIndex + 1}}/{{inputData.length}})
        </div>
    </div>
    <div class="metadata-container">

        <div class="patent-metadata">
            <div class="key-value-pair">
                <div class="key">Patent Number</div>
                <div class="value">{{inputData[selectedPatentIndex].patentNumber}}</div>
            </div>
            <div class="key-value-pair">
                <div class="key">Priority Date</div>
                <div class="value">{{inputData[selectedPatentIndex].priorityDate}}</div>
            </div>
            <div class="key-value-pair">
                <div class="key">Current Assignee</div>
                <div class="value">{{inputData[selectedPatentIndex].currentAssignee}}</div>
            </div>
            <div class="key-value-pair">
                <div class="key">Expiration Date</div>
                <div class="value">{{inputData[selectedPatentIndex].expirationDate}}</div>
            </div>
            <div class="key-value-pair">
                <div class="key">Publication Date</div>
                <div class="value">{{inputData[selectedPatentIndex].publicationDate}}</div>
            </div>
            <!-- patent title -->
            <div class="key-value-pair">
                <div class="key">Title</div>
                <div class="value">{{inputData[selectedPatentIndex].title}}</div>
            </div>
        </div>
        <div class="infringement-score-container">
            <div class="infringement-score">
                <div class="infringement-score-heading">
                    <ng-container *ngIf="currentTab=='industry'">
                        Infringement Score
                    </ng-container>
                    <ng-container *ngIf="currentTab=='pas'">
                        Validity Score
                    </ng-container>
                    <ng-container *ngIf="currentTab=='infringement'">
                        Infringement Score
                    </ng-container>
                </div>
                <div class="infringement-score-value" [class.score-mid]="getScoreValue()>3" [class.score-high]="getScoreValue()>6">
                    <ng-container *ngIf="currentTab=='industry'">
                        {{infringementData['patentInfringementScoreData'][inputData[selectedPatentIndex].patentNumber]}}
                    </ng-container>
                    <ng-container *ngIf="currentTab=='pas'">
                        {{getValidityScore(inputData[selectedPatentIndex].patentNumber)}}
                    </ng-container>
                    <ng-container *ngIf="currentTab=='infringement'">
                        {{infringementData['patentInfringementScoreData'][inputData[selectedPatentIndex].patentNumber]}}
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
    <app-score-legend></app-score-legend>
    <div class="key-value-sep">
        <div class="key">
            Technology in brief
        </div>
        <div class="value">
            {{inputData[selectedPatentIndex].technology}}
        </div>
    </div>
    <div class="key-value-sep" *ngIf="!validitySkip">
        <div class="key">
            Claims Considered
        </div>
        <div class="value">
            {{validityData.claimsConsideredMap[inputData[selectedPatentIndex].patentNumber]}}
        </div>
    </div>
    <div *ngIf="currentTab=='infringement'" class="major-table-container">
        <div *ngFor="let claimData of getFilteredClaimChartData(inputData[selectedPatentIndex].patentNumber); let i=index">
            <div class="claim-chart-iterable">

                <div class="claim-chart-iterable-heading">
                    {{claimData.patent_number}} vs. {{claimData.targeted_product}}
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Claims</th>
                            <th>Infringement</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let claimElement of claimData.data; let j=index">
                            <td>
                                <div class="claim-element-td-container">
                                    {{claimElement.claim_element}}
                                </div>
                            </td>
                            <td>
                                <div class="claim-element-td-contaienr">
                                    <div class="indvidual-part-claim-element">
                                        <b>Analyst Comment: </b> {{claimElement.analyst_comment}}
                                    </div>
                                    <div class="individual-part-claim-element">
                                        <div><b>References:</b></div>
                                        <div *ngFor="let reference of claimElement.references">
                                            <div>{{reference.reference}}</div>
                                        </div>
                                    </div>
                                    <div class="individual-part-claim-element">
                                        <div><b>Sources:</b></div>
                                        <ng-container *ngFor="let reference of claimElement.references; let ind=index">
                                            <a href="{{reference.source}}" target="_blank">{{reference.source}}</a>
                                            <span *ngIf="ind!=claimElement.references.length-1">, </span>

                                        </ng-container>
                                    </div>

                                </div>
                            </td>
                            <td>
                                <div class="claim-element-td-container">
                                    <app-validity-score [score]="claimData.score"></app-validity-score>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
    <div *ngIf="currentTab=='industry'" class="major-table-container">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Current Assignee</th>
                    <th>Targeted Company</th>
                    <th>Company Revenue</th>
                    <th>Targeted Product</th>
                    <th>Product Revenue</th>
                    <th>Global Headquarters</th>
                    <th>US Headquarters</th>
                    <th>State of Incorporation</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of getFilteredIndustryData(inputData[selectedPatentIndex].patentNumber); let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ patent.patentNumber }}</td>
                    <td>{{patent.currentAssignee}}</td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div>
                                {{ company.name }}
                            </div>
                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">

                            <div class="revenue-edit-controls">

                                <div>
                                    {{ company.companyInfo.Revenue.response }}
                                    <!-- <button mat-icon-button (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <mat-icon>edit</mat-icon>
                    </button> -->
                                </div>

                            </div>


                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngFor="let product of company.products;let ind=index">

                                <div [class.last-item]="ind==company.products.length-1">
                                    {{ product.name }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">

                                <div [class.last-item]="ind==company.products.length-1">
                                    {{ product.revenue.response }}
                                    <!-- <ng-container *ngIf="product.revenue.response">
                                        {{product.revenue?.response?.length()}}
                                    </ng-container> -->
                                </div>
                            </div>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies; let j=index">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["Global Headquarters"]["response"] }}
                                </div>
                                <ng-container *ngFor="let product of company.products;let i= index">
                                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies; let j=index">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["US Headquarters"]["response"] }}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let product of company.products;let i= index">
                                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["State of Incorporation"]["response"] }}
                                </div>
                                <ng-container *ngFor="let product of company.products;let i= index">
                                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="currentTab=='pas'" class="major-table-container">
        <div>
            <div *ngFor="let referenceTable of validityData.referenceTableMap[inputData[selectedPatentIndex].patentNumber]; let j=index" class="reference-table-container">
                <div class="reference-table-header-container">
                    <div class="reference-table-header">
                        Reference {{j+1}} {{referenceTable.referenceTitle}}
                    </div>
                    <button class="reference-table-arrow" [class.arrow-closed]="!referenceTableOpenMap(inputData[selectedPatentIndex].patentNumber, j, j)" (click)="toggleReferenceTable(inputData[selectedPatentIndex].patentNumber.patentNumber, j)">
                        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 11.5L11 2.5L2 11.5" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                    </button>
                </div>
                <div class="reference-table-body" [class.body-open]="!referenceTableOpenMap(inputData[selectedPatentIndex].patentNumber, j, j)">
                    <table>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Independent Claim Elements</th>
                                <th>Mapping with <a href="https://patents.google.com/patent/{{inputData[selectedPatentIndex].patentNumber}}" target="_blank"><u>{{inputData[selectedPatentIndex].patentNumber}}</u></a></th>
                                <th>AI Comment</th>
                                <th>Validity Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let claim of referenceTable.claimElements; let k=index">
                                <td>{{k+1}}</td>
                                <td>
                                    <div class="claim-element-td-container">
                                        {{claim.claim_element}}
                                    </div>
                                </td>
                                <td>
                                    <div class="claim-element-td-container">
                                        {{claim.overlap}}
                                    </div>
                                </td>
                                <td>
                                    <div class="claim-element-td-container">
                                        {{claim.ai_comment}}
                                    </div>
                                </td>
                                <td>
                                    <div class="claim-element-td-container" *ngIf="k==0">
                                        <!-- {{claim.validity_score||0}} -->
                                        <app-validity-score [score]="claim.score||0"></app-validity-score>
                                    </div>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>1</td>
                                <td>
                                    <div class="claim-element-td-container">
                                        A method for providing a positioning-assisting signal to a positioning engine, the method comprising:
                                    </div>
                                </td>
                                <td>
                                    Full Overlap
                                </td>
                                <td>
                                    the input sources comprising: one or more cameras, one or more microphones.
                                </td>
                                <td>
                                    7
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- </ng-container> -->
<div *ngIf="exportingState" class="exporting-overlay">
    <div class="exporting-overlay-content">
        <div class="exporting-overlay-heading">
            Exporting Report
        </div>
        <div class="exporting-overlay-text">
            Please wait while we export the report for you.
        </div>
        <div class="exporting-overlay-loader">
            <div class="loader"></div>
        </div>
    </div>
</div>
<div class="exportable-ui" id="exportable-ui" *ngIf="exportUI">
    <div class="lumenci-header" *ngIf="exportingState">
        <div class="lumenci-logo">
            <img src="assets/images/lumenci-logo.png" alt="Lumenci Logo">
        </div>
    </div>
    <div class="sniff-report-heading-container">
        <div class="sniff-report-heading">
            Sniff Test Report
        </div>
        <div class="sniff-report-controls" *ngIf="!exportingState">
            <button class="export-button" (click)="exportPDF()">Export</button>
            <button class="back-button"><svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1044_8739)">
                <path d="M36.8262 37.1449C42.909 31.1216 42.9572 21.3077 36.9339 15.2249C30.9106 9.14211 21.0966 9.09392 15.0139 15.1172C8.93107 21.1406 8.88287 30.9545 14.9062 37.0373C20.9295 43.1201 30.7435 43.1683 36.8262 37.1449Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 22L30.9567 30.9567" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 30.957L30.9567 22.0003" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1044_8739">
                <rect width="38" height="38" fill="white" transform="translate(0 27) rotate(-45)"/>
                </clipPath>
                </defs>
                </svg>
                </button>
        </div>
    </div>

    <!-- <div class="sniff-tab-bar"> -->
    <div class="sniff-tab " [class.sniff-active-tab]="true" (click)="currentTab='industry'">Overview and Competitor Landscape</div>

    <!-- </div> -->

    <div class="sniff-tab-content-wrapper">
        <div class="sniff-tab-content industry-report">
            <div class="industry-report-widgets">
                <div class="industry-report-widget widget-blue">
                    <div class="widget-heading">
                        Patents
                    </div>
                    <div class="widget-main-text">
                        {{industryData.length}}
                    </div>
                    <div class="widget-sub-text">
                        US Patents
                    </div>
                </div>
                <div class="industry-report-widget widget-red" *ngIf="!validitySkip">
                    <div class="widget-heading">
                        Validity
                    </div>
                    <div class="widget-main-text">
                        {{getValidityScoreCount()}}
                    </div>
                    <div class="widget-sub-text">
                        Patents with more than 7 Validity Score
                    </div>
                </div>
                <!-- <div class="industry-report-widget widget-yellow">
                    <div class="widget-heading">
                        Damage
                    </div>
                    <div class="widget-main-text">
                        $12B
                    </div>
                    <div class="widget-sub-text">
                        Estimated Revenue
                    </div>
                </div> -->
                <div class="industry-report-widget widget-green" *ngIf="!validitySkip">
                    <div class="widget-heading">
                        Infringement Companies
                    </div>
                    <div class="widget-main-text">
                        {{infringementArray.length}}
                    </div>
                    <div class="widget-sub-text">
                        No. of infringed companies
                    </div>
                </div>
            </div>
            <div class="industry-table">
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Current Assignee</th>
                            <th>Targeted Company</th>
                            <th>Company Revenue</th>
                            <th>Targeted Product</th>
                            <th>Product Revenue</th>
                            <th>Global Headquarters</th>
                            <th>US Headquarters</th>
                            <th>State of Incorporation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of industryData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>{{patent.currentAssignee}}</td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <div>
                                        {{ company.name }}
                                    </div>
                                    <ng-container *ngFor="let product of company.products;let k= index">
                                        <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">

                                    <div class="revenue-edit-controls">

                                        <div>
                                            {{ company.companyInfo.Revenue.response }}
                                            <!-- <button mat-icon-button (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                            <mat-icon>edit</mat-icon>
                            </button> -->
                                        </div>

                                    </div>


                                    <ng-container *ngFor="let product of company.products;let k= index">
                                        <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <ng-container *ngFor="let product of company.products;let ind=index">

                                        <div [class.last-item]="ind==company.products.length-1">
                                            {{ product.name }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">

                                        <div [class.last-item]="ind==company.products.length-1">
                                            {{ product.revenue.response }}
                                            <!-- <ng-container *ngIf="product.revenue.response">
                                                {{product.revenue?.response?.length()}}
                                            </ng-container> -->
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies; let j=index">
                                    <ng-container *ngIf="company.new!=true">
                                        <div>

                                            {{ company.companyInfo["Global Headquarters"]["response"] }}
                                        </div>
                                        <ng-container *ngFor="let product of company.products;let i= index">
                                            <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies; let j=index">
                                    <ng-container *ngIf="company.new!=true">
                                        <div>

                                            {{ company.companyInfo["US Headquarters"]["response"] }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let product of company.products;let i= index">
                                        <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <ng-container *ngIf="company.new!=true">
                                        <div>

                                            {{ company.companyInfo["State of Incorporation"]["response"] }}
                                        </div>
                                        <ng-container *ngFor="let product of company.products;let i= index">
                                            <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <ng-container *ngIf="!validitySkip">

            <div class="sniff-tab" [class.sniff-active-tab]="true" (click)="currentTab='pas'">Validity</div>

            <div class="sniff-tab-content pas-report">
                <div class="pas-table">
                    <div class="legend-flex-end">
                        <app-score-legend></app-score-legend>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Patent Number</th>
                                <th>Title</th>
                                <th>Current Assignee</th>
                                <th>Validity Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let patent of validityData.summaryData; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ patent.patentNumber }}</td>
                                <td>{{patent.title}}</td>
                                <td>{{patent.assignee}}</td>
                                <td>
                                    <app-validity-score [score]="patent.score"></app-validity-score>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </ng-container>

        <div class="sniff-tab" [class.sniff-active-tab]="true" (click)="currentTab='infringement'">Infringement Assessment</div>
        <div class="sniff-tab-content infringement-report">

            <div class="infringement-table">
                <div class="legend-flex-end">
                    <app-score-legend></app-score-legend>
                </div>
                <!-- <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Title</th>
                            <th>Priority Date</th>
                            <th>Current Assignee</th>
                            <th>Validity Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of validityData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>Automated cinematic decisions based on descriptive models</td>
                            <td>2019-01-01</td>
                            <td>Cilag GmbH International</td>
                            <td>8</td>
                        </tr>
                    </tbody>
                </table> -->
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Patent Number</th>
                            <th>Targeted Company</th>
                            <th>Targeted Product</th>
                            <th>Infringement Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patent of infringementArray; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ patent.patentNumber }}</td>
                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <div>
                                        {{ company.name }}
                                    </div>
                                    <ng-container *ngFor="let product of company.products;let k= index">
                                        <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>

                            <td>
                                <ng-container *ngFor="let company of patent.companies">
                                    <ng-container *ngFor="let product of company.products;let ind=index">

                                        <div [class.last-item]="ind==company.products.length-1">
                                            {{ product.name }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <app-validity-score [score]="infringementData['patentInfringementScoreData'][patent.patentNumber]"></app-validity-score>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>