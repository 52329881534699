<div class="sniff-report-heading-container">
    <div class="sniff-report-heading">
        Invalidity Check
    </div>
</div>

<ng-container *ngIf="selectedPatentIndex==null">
    <!-- Widgets Row (reusing 'industry-report-widgets' & widget color classes) -->
    <div class="industry-report-widgets">
        <!-- Total Patents -->
        <div class="industry-report-widget widget-blue">
            <div class="widget-heading">Total Patents</div>
            <div class="widget-main-text">
                {{ getInvalidityStats().total }}
            </div>
            <div class="widget-sub-text">
                US Patents
            </div>
        </div>

        <!-- Low Invalidity Level -->
        <div class="industry-report-widget widget-red">
            <div class="widget-heading">Low Invalidity Level</div>
            <div class="widget-main-text">
                {{ getInvalidityStats().lowCount }}
            </div>
            <div class="widget-sub-text">
                Patent within score range 1-3
            </div>
        </div>

        <!-- Medium Invalidity Level -->
        <div class="industry-report-widget widget-yellow">
            <div class="widget-heading">Medium Invalidity Level</div>
            <div class="widget-main-text">
                {{ getInvalidityStats().midCount }}
            </div>
            <div class="widget-sub-text">
                Patent within score range 4-6
            </div>
        </div>

        <!-- High Invalidity Level -->
        <div class="industry-report-widget widget-green">
            <div class="widget-heading">High Invalidity Level</div>
            <div class="widget-main-text">
                {{ getInvalidityStats().highCount }}
            </div>
            <div class="widget-sub-text">
                Patent within score range 7-10
            </div>
        </div>
    </div>

    <!-- Table (reusing 'industry-table') -->
    <div class="industry-table">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Title</th>
                    <th>Priority Date</th>
                    <th>Current Assignee</th>
                    <th>Invalidity Score</th>
                    <th>Detailed Report</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of summaryData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <a [href]="'https://patents.google.com/patent/' + patent.patentNumber" target="_blank">
        {{ patent.patentNumber }}
      </a>
                    </td>
                    <td>{{ patent.title }}</td>
                    <td>{{ patent.priorityDate || 'N/A' }}</td>
                    <td>{{ patent.assignee }}</td>
                    <td>{{ patent.score }}</td>
                    <td>
                        <a class="view-report-link" (click)="viewReportForPatent(i)">
        View Report
      </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>