import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-excavation-dialog',
  template: `
    <div class="dialog-container">
      <div class="dialog-header">
      <h2>The excavation portfolio is about to begin!</h2>
      <div class="cross-button">
        <button class="btn btn-icon" (click)="dialogRef.close()"><i class="material-icons">close</i></button>
</div>
  </div>
      <p class="body-text">This workflow consists of 3 stages: Validity check, Damage analysis, Infringement assessment.</p>
      <p class="bold-texxt" >How do you want to proceed?</p>
      <div class="button-container">
        <button class="btn btn-primary" color="primary" (click)="onSelect('full')">Full Excavation</button>
        <button class="btn btn-outline" color="accent" (click)="onSelect('skip')">Skip Validity Check</button>
      </div>
    </div>
  `,
  styles: [`
    .dialog-container {
      text-align: center;
      padding: 20px;
    }

    .dialog-header{
      display:flex;
      justify-content:space-between;
    }
    h2 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 23px;
    }
    .body-text{
      font-size:12px;
    }
    .btn-icon{
      padding-block: 0;
    padding-right: 0;
    outline: none !important;
    box-shadow:none !important;
    }
    .bold-text{
      font-weight:700;
    }

    p {
      margin: 5px 0;
    }
    .button-container {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
    }
    button {
      font-size: 16px;
    }
  `]
})
export class SkipPasPopupComponent {
  constructor(public dialogRef: MatDialogRef<SkipPasPopupComponent>) {}

  onSelect(option: 'full' | 'skip'): void {
    this.dialogRef.close(option);
  }
}
