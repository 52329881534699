<header class="header">
    <a href="/">
        <img src="assets/images/lumenci-logo.png" alt="Logo" class="logo" />
    </a>
    <button mat-icon-button [matMenuTriggerFor]="userMenu">
            <i class="material-icons">account_circle</i>
        </button>
    <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="onLogout()">Logout</button>
    </mat-menu>

</header>