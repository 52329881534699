import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {ProgressBarLoaderComponent} from '../common/progress-bar-loader/progress-bar-loader.component';
import { FormsModule } from '@angular/forms';
import { HttpService } from '../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkInputPopupComponent } from '../common/popups/link-input-popup/link-input-popup.component';
import { FileUploadPopupComponent } from '../common/popups/file-upload-popup/file-upload-popup.component';

@Component({
  selector: 'app-industry-analysis',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, FormsModule, HttpClientModule, ProgressBarLoaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './industry-analysis.component.html',
  styleUrls: ['./industry-analysis.component.css']
})
export class IndustryAnalysisComponent implements OnInit {
  isLoading: boolean = false;
  @Input() patentData: { patentNumber: string, currentAssignee:string }[] = [];
  currentStage: number = 0;
  loadingText: string = 'Loading, please wait';
  titleText: string = 'Target Scouting';
  patentDetails: any[] = [];
  mock: boolean = false; // Set this to true for mock responses
  isEditingCompanies: boolean = false;
  isEditingProducts: boolean = false;
  @Input() updateData:any;
  @Input() updateRoute:any;

  editingExtraDetails:any={};

  constructor(private http: HttpService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.startTargetScouting();
  }

  startTargetScouting(): void {
    this.isLoading = true;
    this.loadingText = 'Retrieving Target Companies, please wait';
    if(this.mock){
      setTimeout(() => {
        this.getTargetCompanies();
      }, 0);
    }
    else{

      this.getTargetCompanies();
    }
  }

  toggleEditingExtraDetails(value:any){
    if(this.editingExtraDetails[value]){
      this.editingExtraDetails[value]=false;
    }
    else{
      this.editingExtraDetails[value]=true;
    }
  }

  getTargetCompanies(): void {
    if (this.mock) {
      // Mock response for target companies
      this.patentDetails = this.patentData.map(patent => ({
        ...patent,
        companies: [
          {
            name: 'Alpha Corp.',
            products: []
          },
          {
            name: 'Beta Inc.',
            products: []
          }
        ]
      }));
      this.getCompanyInformation();
    } else {
      console.log(this.patentData)
      const requests = this.patentData.map(patent =>
        this.http.post('/targetCompanies', { patentNumber: patent.patentNumber })
      );

      Promise.all(requests).then(responses => {
        this.patentDetails = responses.map((res: any, index: number) => ({
          // patentNumber: this.patentData[index].patentNumber,
          companies: res.targetCompanies.map((company: string) => ({ name: company, products: [] })),
          ...this.patentData[index]
        }));
      }).finally(()=>{
        this.getCompanyInformation();
      })
      .catch(error => {
        console.error('Error fetching target companies:', error);
      });
    }
  }

  

  getCompanyInformation(){
    this.isLoading = true;
    this.loadingText = 'Retrieving Company Information, please wait';
     setTimeout(() => {
      this.getCompanyInfo();
    }, 2000);
  }

  getCompanyInfo(): void {
  if(this.mock)
  {
    /*"CompanyInformation": {

    "Global Headquarters": {

      "response": "",

      "sourceLink": ""

    },

    "US Headquarters": {

      "response": "",

      "sourceLink": ““

    },

    "State of Incorporation": {

      "response": "",

      "sourceLink": ""

    },

    "Revenue": {

      "response": "",

      "sourceLink": ""

    }

  }*/
//  this is the mock response for company information
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        console.log(patentInfo)
        company.companyInfo = {
          "Global Headquarters": {

            "response": "New York, NY",

            "sourceLink": "N/A"

          },

          "US Headquarters": {

            "response": " New York, NY",

            "sourceLink": "N/A"

          },

          "State of Incorporation": {

            "response": " Delaware",

            "sourceLink": "N/A"

          },

          "Revenue": {

            "response": "Revenue: $50-60 million USD",

            "sourceLink": "N/A"

          }
        };
      });
    });
    this.isLoading = false;
    this.currentStage = 2;
  }
  else {
    const requests = this.patentDetails.flatMap(patentInfo =>
      patentInfo.companies.map((company: any) =>
        this.http.post('/extractCompanyInformation', {
          patentNumber: patentInfo.patentNumber,
          companyName: company.name,
          modelName: 'perplexity-llama-3.1-sonar'
        })
      )
    );

    Promise.all(requests).then(responses => {
      console.log('response to api')
      let index = 0;
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company: any) => {
          console.log(responses[index].CompanyInformation)
          company.companyInfo = responses[index++].CompanyInformation;
        });
      });
    }).finally(()=>{
      this.isLoading = false;
      console.log('post api')
      this.currentStage = 2;
    })
    .catch(error => {
      console.error('Error fetching company information:', error);
      // this.isLoading = false;
    });
  }

  }

  getNewCompanyInfo(){
    console.log(this.patentDetails)
    let companyList:any=[]
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        if(company.new){
          companyList.push({patentNumber:patentInfo.patentNumber, companyName:company.name});
        }
      });
    });
    console.log(companyList)
  //  this would consist a list of patent number and company name. use the same logic but update on the same patent number and company name. this function would be making the api call. company list structure {patentNumber, companyName}
  this.isLoading = true;  
  this.loadingText = 'Retrieving Company Information, please wait';
  if(this.mock){
    // based on response, update the new companies. all new companies have new in their object.
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        if(company.new){
          company.companyInfo = {
            "Global Headquarters": {

              "response": "New York, NY",

              "sourceLink": "N/A"

            },

            "US Headquarters": {

              "response": " New York, NY",

              "sourceLink": "N/A"

            },

            "State of Incorporation": {

              "response": " Delaware",

              "sourceLink": "N/A"

            },

            "Revenue": {

              "response": "Revenue: $50-60 million USD",

              "sourceLink": "N/A"

            }
          };
          company.new=false
        }
      });
    });
    this.isLoading = false;
  }
  else 
  {
    const requests = companyList.map((company:any) =>
      this.http.post('/extractCompanyInformation', {
        patentNumber: company.patentNumber,
        companyName: company.companyName,
        modelName: 'perplexity-llama-3.1-sonar'
      })
    );

    // only update the company info for the company that was updated, match on the basis of patent number as well 
    Promise.all(requests).then(responses => {
      let index = 0;
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company: any) => {
          console.log(company, company.new)
          if(company.new){
            company.companyInfo = responses[index++].CompanyInformation;
          }
          company.new=false
        });

      });
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching company information:', error);
      this.isLoading = false;
    });

  }

  }



  proceedToTargetProducts(): void {
    this.isLoading = true;
    this.loadingText = 'Retrieving Target Products, please wait';
    setTimeout(() => {
      this.getTargetProducts();
    }, 2000);
  }

  getTargetProducts(): void {
    if (this.mock) {
      // Mock response for target products
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.products = [
            { name: 'Product A' },
            { name: 'Product B' }
          ];
        });
      });
      this.isLoading = false;
      this.currentStage = 3;
    } else {
      const requests = this.patentDetails.flatMap(patentInfo =>
        patentInfo.companies.map((company: any) =>
          this.http.post('/productInformation', {
            patentNumber: patentInfo.patentNumber,
            companyName: company?.name,
            modelName: 'perplexity-llama-3.1-sonar'
          })
        )
      );

      Promise.all(requests).then(responses => {
        let index = 0;
        this.patentDetails.forEach(patentInfo => {
          patentInfo.companies.forEach((company: any) => {
            company.products = responses[index++].ProductInformation['Targeted products'].map((product: any) => ({ name: product.response }));
          });
        });
      }).finally(()=>{
        this.isLoading = false;
        this.currentStage = 3;
      }).catch(error => {
        console.error('Error fetching target products:', error);
        this.isLoading = false;
      });
    }
  }

  proceedToRevenueIdentification(): void {
    this.isLoading = true;
    this.loadingText = 'Retrieving Revenue Details, please wait';
    setTimeout(() => {
      this.getRevenueDetails();
      this.titleText = 'Revenue Identification';
    }, 2000);
  }

  getRevenueDetails(): void {
    if (this.mock) {
      // Mock response for revenue details
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.revenue = [
            {
              response: ' $50-60 million USD',
              sourceLink: 'N/A'
            }
          ];
          company.products.forEach((product: any) => {
            product.revenue = ' $5-10 million USD';
          });
        });
      });
      this.isLoading = false;
      this.currentStage = 4;
    } else {
      const requests = this.patentDetails.flatMap(patentInfo =>
        patentInfo.companies.map((company: any) =>
          this.http.post('/revenueInformation', {
            patentNumber: patentInfo.patentNumber,
            companyName: company.name,
            productList: company.products.map((product: any) => product.name),
            modelName: 'perplexity-llama-3.1-sonar'
          })
        )
      );

      Promise.all(requests).then(responses => {
        let index = 0;
        this.patentDetails.forEach(patentInfo => {
          patentInfo.companies.forEach((company: any) => {
            company.products.forEach((product: any) => {
              /*{

  "RevenueInformation":[ {

        "Verizon Media Smartplay Prebid": {

          "response": "Information not available",

          "sourceLink": "Information not available"

            }},{

        "Verizon Media DSP": {

          "response": "Information not available",

          "sourceLink": "Information not available"}

            }},{

        "Next-Gen Solutions": {

          "response": "Information not available",

          "sourceLink": "Information not available"

            }}

      }

]
}
  }*/
              // map the product revenue by matching the product name
              // console.log(responses[index], product)
              // console.log('finding revenue for:', product.name)
              // console.log('response:', responses[index].RevenueInformation["Product Revenue"])
              if(Object.keys(responses[index].RevenueInformation["Product Revenue"]).includes(product.name)){
                console.log('response value:', responses[index].RevenueInformation["Product Revenue"][product.name])
                product.revenue = responses[index].RevenueInformation["Product Revenue"][product.name]
                console.log(product)
              }
              // else{
              //   product.revenue = {response:'Unknown', source:'Unknown'}
              // }
              //  responses[index].RevenueInformation["Product Revenue"].forEach((revenue:any) => {
            //   console.log('comparing strings:', Object.keys(revenue), product.name)
            //   if(Object.keys(revenue) === product.name){
            //     console.log('response value:', revenue[product.name])
            //     console.log(revenue)
            //     product.revenue = revenue[product.name]
            //     console.log(product)
            //   }
            // });
          });
            index++;
        });
      })
    }).finally(()=>{
      this.isLoading = false;
      this.currentStage = 4;
    }).catch(error => {
        console.error('Error fetching revenue details:', error);
        this.isLoading = false;
      });
    }
  }

  toggleEditCompanies(): void {
    this.isEditingCompanies = !this.isEditingCompanies;
    if(this.isEditingCompanies){
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.new = false;
        });
      });
    }
    else {
      this.getNewCompanyInfo();
    }
  }

  handleCompanyChange(event:any, company:any)
  {
    console.log(event, company)
    company.new=true;
  }

  addCompany(patent: any): void {
    patent.companies.push({ name: '', products: [], new:true });
  }

  confirmEditCompany(): void {
    this.isEditingCompanies = false;
    this.getNewCompanyInfo();
  }

  toggleEditProducts(): void {
    this.isEditingProducts = !this.isEditingProducts;
  }

  addProduct(company: any): void {
    company.products.push({ name: '' });
  }

  confirmEditProduct(): void {
    this.isEditingProducts = false;
  }
  deleteCompany(patent: any, companyIndex: number): void {
    patent.companies.splice(companyIndex, 1);
  }
  deleteProduct(company: any, productIndex: number): void {
    company.products.splice(productIndex, 1);
  }
  saveCompanies(): void {
    this.isEditingCompanies = false;
  }
  saveProducts(): void {
    this.isEditingProducts = false;
  }

  handlePopupSubmit(value: string): void {
    console.log('Value received from popup:', value);
  }
  openPopup(patentNumber:any, productName:any, companyName:any): void {
    const dialogRef = this.dialog.open(LinkInputPopupComponent, {
      width: '300px',
      data: { inputValue: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('Value received from popup:', result);
        this.handleInputPopup(result, patentNumber, productName, companyName);
      }
    });
  }

  assistMode=false;

  toggleAssistMode(){
    this.assistMode=!this.assistMode
  }

  handleInputPopup(value: string, patentNumber:any, productName:any, companyName:any): void {
    /*{

  "url": "https://en.wikipedia.org/wiki/Verizon",

  "patentNumber": "US6421675B1",

  "productName": "",

  "companyName": "Verizon Media Inc."

}

Response:

{

  "revenueInformationURLModel": {

    "response": "245.1 - 245.1 billion",

    "sourceLink": "https://www.microsoft.com/en-us/investor/earnings/fy-2024-q4/press-release-webcast"

  }*/
 // make this api call and update the revenue information for the product. if product is "", then you need to update the revenue information of the company
  this.isLoading = true;
  this.loadingText = 'Retrieving Revenue Details, please wait';
  this.http.post('/revenueURLInformation', {
    url: value,
    patentNumber: patentNumber,
    productName: productName,
    companyName: companyName
  }).then(response => {
    console.log(response, response.revenueInformationURLModel)
    if(productName === ""){
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          if(company.name === companyName){
            company.revenue = [
              {
                response: response.revenueInformationURLModel.response,
                sourceLink: response.revenueInformationURLModel.sourceLink
              }
            ];
          }
        });
      });
    }
    else{
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          if(company.name === companyName){
            company.products.forEach((product:any) => {
              if(product.name === productName){
                product.revenue = response.revenueInformationURLModel;
                console.log('updating product revenue:', product.revenue, response.revenueInformationURLModel.response);
                console.log(product)
              }
            });
          }
        });
      });
    }
    this.isLoading = false;
  }).catch(error => {
    console.error('Error fetching revenue details:', error);
    this.isLoading = false;
  });
  }


  openFileUploadPopup(patentNumber:any, productName:any, companyName:any): void {
    const dialogRef = this.dialog.open(FileUploadPopupComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('File received from popup:', result);
        this.handleProductRevenueFileUpload(result, patentNumber, productName, companyName);
      }
    });
  
}

  handleProductRevenueFileUpload(file:any, patentNumber:any, productName:any, companyName:any ){
    /*
    Endpoint: /revenuePDFInformation

Resquest: 

Body:

File

Parameters:

patentNumber

productName

companyName

Response:

{

  "revenueInformationPDFModel": {

    "response": "168,088 - 211,915 million",

    "sourceLink": "https://storage.googleapis.com/infringement_detector/revenue%20information/0000950170-23-035122.pdf"

  }

}*/
// make this api call and update the revenue information for the product. if product is "", then you need to update the revenue information of the company
this.isLoading = true;
this.loadingText = 'Retrieving Revenue Details, please wait';
// 
const formData=new FormData();
formData.append('file', file);
this.http.postFormData('/revenuePDFInformation?patentNumber',  formData, {
  params: {patentNumber: patentNumber, productName: productName, companyName: companyName}
}).then(response => {
  if(productName === ""){
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        console.log(company, companyName)
        if(company.name === companyName){
          company.companyInfo.Revenue = {
              response: response.revenueInformationPDFModel.response,
              sourceLink: response.revenueInformationPDFModel.sourceLink
            }
        }
      });
    });
  }
  else{
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        if(company.name === companyName){
          company.products.forEach((product:any) => {
            console.log(product, productName)
            if(product.name === productName){
              product.revenue = response.revenueInformationPDFModel;
            }
          });
        }
      });
    });
  }
    this.isLoading = false;
})
  .catch(error => {
    console.error('Error fetching revenue details:', error);
    this.isLoading = false;
  });
  }

  showRevenueControls(company:any){
    if(company.showRevenue){

      company.showRevenue=false;
    }
    else{
      company.showRevenue=true;
    }
  }

  showProductRevenueControls(product:any){
    if(product.showRevenue){

      product.showRevenue=false;
    }
    else{
      product.showRevenue=true;
    }
  }

  onFinalNext()
  {
    console.log(this.patentDetails)
    this.updateData(this.patentDetails, "industry")
    this.updateRoute("infringement-analysis")
  }
  minStep=2;

  goBack(){
    if(this.currentStage>this.minStep)
      this.currentStage--;
  }
}
