<!-- Step 1: Upload -->
<div class="header-controls">

    <div class="right-controls">
        <!-- <button class="next-button" (click)="onClickNext()">Next</button> -->
        <button class="btn-primary" (click)="generateReport()">Generate Report</button>
    </div>
</div>
<table class="patent-table">
    <thead>
        <tr>
            <th>S. No.</th>
            <th>Publication Number</th>
            <th>Title</th>
            <th>Current Assignee</th>
            <th>Priority Date</th>
            <th>Expiration Date</th>
            <th>Claims</th>
            <th>Technology</th>
            <th>Number of Claims (Independent)</th>
            <th>Number of Claims (Dependent)</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let section of allSectionsJson; let i = index">
            <td>{{ i + 1 }}</td>
            <td class="publication-number">
                <a href="https://patents.google.com/patent/{{ section['Publication Number'] }}" target="_blank">
                        {{ section['Publication Number'] }}
                    </a>
            </td>
            <td>{{ section['Title'] }}</td>
            <td>{{ section['Current Assignee'] }}</td>
            <td>{{ section['Priority Date'] }}</td>
            <td>{{ section['Expiration Date'] }}</td>
            <td class="claims-cell" (click)="openClaimsDialog(section['Claims'])">
                {{ section['Claims'][0].slice(0, 15) }}...
            </td>
            <td>{{ section['Technology'] }}</td>
            <td>{{ section['Independent Claims'] }}</td>
            <td>{{ section['Dependent Claims'] }}</td>
            <td>{{ section['Patent Status'] }}</td>
        </tr>
    </tbody>
</table>