import { Component, Input, Output, EventEmitter, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DataService } from '../services/data.service';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-infringement-dashboard',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './infringement-dashboard.component.html',
  styleUrls: ['./infringement-dashboard.component.css']
})
export class InfringementDashboardComponent implements OnInit {
  // Input summary data (each patent with properties like patentNumber, title, priorityDate, assignee, score)
  @Input() inputData: any[] = [];
  @Input () industryData:any=[];

  claimChartData:any[]=[];
  selectedPatentIndex:any=null;

  // Detailed patent data used to compute damage values (each patent should include companies and their products with revenue)
  @Input() patentDetails: any[] = [];

  @Input() infringementData:any={};

  @Input() validityData:any={};

  @Input() validitySkip:boolean=true;

  exportUI:boolean=false;
  exportingState=false;
  currentTab="infringement";


  // Emit the index of the patent when "View Report" is clicked
  viewReport(number:any){
    console.log(number)
  }

  ngOnInit(): void {}
 constructor(private dataService:DataService) { 
    this.inputData=this.dataService.inputData;
    this.validityData=this.dataService.pasData;
    this.industryData=this.dataService.industryData;
    this.infringementData=this.dataService.infringementData;
    this.validitySkip=this.dataService.skipPas;
    // console.log('skip pas:', this.validitySkip, this.dataService.skipPas)
  }
  getRevenueSumDetails(patent:any) {
    let revenueArray: any[] = [];
    
    // Iterate over the passed products array to collect revenue responses.
    patent.companies.forEach((company: any) => {
      company.products.forEach((product: any) => {
      revenueArray.push(product.revenue.response);
    });});
  
    console.log(revenueArray);
    const revenueRange = this.sumCurrencies(revenueArray);
    console.log(revenueRange);
    return revenueRange;
  }
  // Parse a revenue string into a numeric range {min, max}
  parseCurrencyRange(value: string): { min: number, max: number } {
    const result = { min: 0, max: 0 };
    if (!value) return result;
    // Remove any parentheses or bracketed parts
    let cleaned = value.split(/[\(\[]/)[0].trim();
    if (!cleaned) return result;
    const regex = /\$?\s*([\d.,]+)\s*(million|billion|trillion)?\s*(?:-\s*\$?\s*([\d.,]+))?\s*(million|billion|trillion)?/i;
    const match = cleaned.match(regex);
    if (!match) return result;
    const denominations: any = { trillion: 1e12, billion: 1e9, million: 1e6 };
    const baseValue = parseFloat(match[1].replace(/,/g, ''));
    let unit1 = match[2] ? match[2].toLowerCase() : 'million';
    const hasRange = !!match[3];
    const secondValue = hasRange ? parseFloat(match[3].replace(/,/g, '')) : baseValue;
    let unit2 = match[4] ? match[4].toLowerCase() : unit1;
    const denom1 = denominations[unit1] || 1e6;
    const denom2 = denominations[unit2] || denom1;
    let low = baseValue * denom1;
    let high = secondValue * denom2;
    if (low > high) { [low, high] = [high, low]; }
    result.min = low;
    result.max = high;
    return result;
  }

  // Format a numeric range to a currency string (e.g. "$10.0 M" or "$10.0 M - $15.0 M")
  formatCurrencyRange(min: number, max: number): string {
    function shortFormat(val: number): string {
      const denominations: any = { billion: 1e9, million: 1e6 };
      if (val >= 1e9) {
        const v = val / denominations.billion;
        return `$${v.toFixed(1)} B`;
      } else {
        const v = val / denominations.million;
        return `$${v.toFixed(1)} M`;
      }
    }
    return (min === max) ? shortFormat(min) : `${shortFormat(min)} - ${shortFormat(max)}`;
  }

  // Sum an array of revenue strings into a total damage range string
  sumCurrencyRanges(values: string[]): string {
    let totalMin = 0;
    let totalMax = 0;
    for (const val of values) {
      const { min, max } = this.parseCurrencyRange(val);
      totalMin += min;
      totalMax += max;
    }
    function shortFormat(num: number): string {
      const denominations: any = { billion: 1e9, million: 1e6 };
      if (num >= 1e9) {
        const v = num / denominations.billion;
        return `$${Math.floor(v * 10) / 10} B`;
      } else {
        const v = num / denominations.million;
        return `$${Math.floor(v * 10) / 10} M`;
      }
    }
    return (totalMin === totalMax)
      ? shortFormat(totalMin)
      : `${shortFormat(totalMin)} - ${shortFormat(totalMax)}`;
  }

  // For a given patent (from summaryData), compute its overall damage value using detailed patentDetails.
  getDamageValueForPatent(patent: any): string {
    let revenueValues: string[] = [];
    // Find the matching patent in the detailed data by patent number
    const matchingPatent = this.patentDetails.find(p => p.patentNumber === patent.patentNumber);
    if (matchingPatent && matchingPatent.companies) {
      matchingPatent.companies.forEach((company: any) => {
        if (company.products) {
          company.products.forEach((product: any) => {
            if (product.revenue && product.revenue.response) {
              revenueValues.push(product.revenue.response);
            }
          });
        }
      });
    }
    return this.sumCurrencyRanges(revenueValues);
  }

  // When a user clicks the "View Report" link, emit the index so the parent can navigate.
  onViewReport(index: number) {
    this.viewReport(index);
  }
  
  getFilteredClaimChartData(patentNumber:string){
    return this.claimChartData.filter((item:any)=>item.patent_number==patentNumber)
  }
  getFilteredIndustryData(patentNumber:string){
    return this.industryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getSummaryData(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getValidityScoreCount(){
    if(this.validitySkip)
      return 0;
    return this.validityData.summaryData.filter((x:any)=>x.score>7).length
  }

  getValidityScore(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)[0].score
  }
  referenceTableOpenMapObj:any={};
  toggleReferenceTable(patentNumber:any, referenceNumber:any){
    if(!this.referenceTableOpenMapObj[patentNumber]){
      this.referenceTableOpenMapObj[patentNumber]={};
      this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
  }

  referenceTableOpenMap(patentNumber:any, referenceNumber:any, index:any){
    // map based on patentNumber and further referenceNumber. If the reference Object does not exist, create a new object with the referenceNumber as key and value as false
    if(!this.referenceTableOpenMapObj[patentNumber]){
      if(index==0&&this.referenceTableOpenMapObj[patentNumber]===undefined){
        this.referenceTableOpenMapObj[patentNumber]={};
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else
      this.referenceTableOpenMapObj[patentNumber]={};
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
    return this.referenceTableOpenMapObj[patentNumber][referenceNumber];
  }

  getScoreValue(){
    if(this.selectedPatentIndex!=null){
      if(this.currentTab=='pas')
        return this.getValidityScore(this.inputData[this.selectedPatentIndex].patentNumber)

      return this.infringementData['patentInfringementScoreData'][this.inputData[this.selectedPatentIndex].patentNumber]
    }
    return 0;
  }

  setSelectedPatentIndex(patentNumber:string){
    console.log(patentNumber)
    let index=this.inputData.findIndex((item:any)=>item.patentNumber==patentNumber);
    this.selectedPatentIndex=index;
    console.log(index, this.inputData[index])
  }

  exportPDF(){
    this.exportingState=true;
    this.exportUI=true;
    setTimeout(() => {
    var element:any = document.getElementById('exportable-ui');
    
    if(this.selectedPatentIndex!=null){
      element=document.getElementById('major-report');
      console.log(element)
    }
    element.classList.add('min-height');
    let opt={
      filename:'sniff-report.pdf',
      width: 2500,
      height:100,

    }
      
    //      //$("#previewBeforeDownload").html(canvas);
    //      var imgData = canvas.toDataURL("image/jpeg",1);
    //      var pdf = new jsPDF("p", "px", "a2");
    //      // var pageWidth = pdf.internal.pageSize.getWidth();
    //      var pageWidth=pdf.internal.pageSize.getWidth();
    //      var pageHeight = pdf.internal.pageSize.getHeight();
    //      var imageWidth = canvas.width;
    //      var imageHeight = canvas.height;
   
    //      var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
    //      //pdf = new jsPDF(this.state.orientation, undefined, format);
    //      pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
    //      pdf.save("sniff-report.pdf");
    //  });
   // Get the dimensions of the HTML element
const elementHeight = element.offsetHeight;
const elementWidth = element.offsetWidth;

// Estimate the timeout based on the element's height (e.g., 1ms per pixel)
const estimatedTimeout = elementHeight * 1; // Adjust the multiplier as necessary

html2canvas(element).then(canvas => {
  var imgData = canvas.toDataURL("image/jpeg", 1);
  
  // Get the dimensions of the canvas
  var imageWidth = canvas.width;
  var imageHeight = canvas.height;

  // Calculate the required PDF dimensions
  var pdfWidth = imageWidth;
  var pdfHeight = imageHeight;
  console.log('canvas details', canvas.width, canvas.height)

  // Create the PDF with the calculated dimensions
  var pdf = new jsPDF("p", "px", [pdfWidth, pdfHeight]);

  // Add the image to the PDF
  pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

  // Save the PDF
  pdf.save("sniff-report.pdf");
}).finally(() => {
  // setTimeout(() => {
    this.exportingState = false;
    this.exportUI = false;
    element.classList.remove('min-height');
  // }, estimatedTimeout); // Use the estimated timeout
});
    }, 10);
    }

    calculateInfringedCompanies(){
        
    }

    calculateRevenue(){
      
    }

    changeIndex(index:number){
      if(this.selectedPatentIndex+index>=0 && this.selectedPatentIndex+index<this.inputData.length){
        this.selectedPatentIndex+=index;
      }
    }

    getRevenueDetails(){
      let revenueArray:any=[];
      this.industryData.forEach((element:any) => {
        let dataArray=element.companies;
      for(let data of dataArray){
        let companyData={
          name:data.name,
          products:[]
        }
        for(let product of data.products){
          revenueArray.push(product.revenue.response)
        }
      }
    });

      console.log(revenueArray)
      let revenueString=this.sumCurrencies(revenueArray);
      console.log(revenueString)
      return revenueString;


    }

     sumCurrencies(values:any) {
        const denominations:any = {
            billion: 1e9,
            million: 1e6
        };
    
        let total = 0;
    
        for (let value of values) {
            value = value.split(/[\(\[]/)[0];
            const match = value.match(/\$?([\d.,]+ (million|billion|trillion)?)\s*(-\s*(\$?\s*[\d.,]+))?\s*(million|billion|trillion)?/i);
            if (!match) continue;
            console.log(match)
            const baseValue = parseFloat(match[1]);
            match[4] = match[4] ? match[4].replace('$', '') : null;
            const rangeEnd = match[4] ? parseFloat(match[4]) : null;
            let unit_candidate=match[5];
            
            if(!unit_candidate){
                unit_candidate=match[2];
            }
            let unit = unit_candidate ? unit_candidate.toLowerCase() : "million";
            unit = [unit, unit];
            if(unit_candidate&&match[2]&&match[2]!=match[5]){
              let correctUnit=match[2].toLowerCase();
              let candidateUnit=unit_candidate.toLowerCase();
              if(Object.keys(denominations).includes(candidateUnit)&&Object.keys(denominations).includes(correctUnit)){
              unit=[correctUnit, unit[1]];
              
              }
            }
    
            if (rangeEnd && !value.includes('- $')) {
                total += (baseValue * denominations[unit[0]] + rangeEnd * denominations[unit[1]]) / 2;
            } else {
                const finalValue = rangeEnd
                    ? (baseValue * denominations[unit[0]] + rangeEnd * denominations[unit[1]]) / 2
                    : baseValue * denominations[unit[0]];
    
                total += finalValue;
            }
        }
    
        if (total >= 1e9) {
            const totalInBillions = total / denominations.billion;
            const truncated = Math.floor(totalInBillions * 10) / 10;
            const hasExtra = (totalInBillions * 100) % 10 > 0;
            return hasExtra
                ? `$${truncated.toFixed(1)}+ B`
                : `$${truncated.toFixed(1)} B`;
        } else {
            const totalInMillions = total / denominations.million;
            const truncated = Math.floor(totalInMillions * 10) / 10;
            const hasExtra = (totalInMillions * 100) % 10 > 0;
            return hasExtra
                ? `$${truncated.toFixed(1)}+ M`
                : `$${truncated.toFixed(1)} M`;
        }
    
        return '$0';
    }
    getLinkOrAiEstimate(str:any){
      if(!str){
        return "Unknown";
      }
      if(str.includes('http')){
        return `<a href=${str} target="_blank">${str}</a>`
      }
      else if(str=='Unknown')
      {
        return "Unknown";
      }
      else{
        let target = str.split('(')[1];
        if(!target){
          return str;
        }
        target = target.slice(0, -1);
        return `<h6>AI Estimate</h6> <p>${target}</p>`
      }
    }
    

}
