import { Directive, ElementRef, Renderer2, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[appTruncateTextWidth]',
  standalone: true
})
export class TruncateTextWidthDirective implements AfterViewInit {
  @Input() maxWidth: number = 180;  // Max width in pixels, default is 300px

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.truncateTextIfNeeded();
  }

  private truncateTextIfNeeded() {
    const element = this.el.nativeElement;
    const textNode = element.firstChild;  // Only target the first child text node
    const initialText = textNode?.textContent.trim();
    console.log('truncateTextIfNeeded', initialText);
    // Only truncate if the element contains text and has children
    if (initialText && textNode) {
        const spanWidth = element.offsetWidth;
        
        if (spanWidth > this.maxWidth) {
            // Truncate the text if it exceeds maxWidth
            const truncatedText = this.truncateText(initialText, this.maxWidth);
            this.renderer.setProperty(textNode, 'textContent', truncatedText);
        }

        // Set the initial text as the title attribute for hover tooltip
        this.renderer.setAttribute(element, 'title', initialText);
    }
}

  private truncateText(text: string, maxWidth: number): string {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';  // Hide the span while calculating text length
    span.style.whiteSpace = 'nowrap';
    span.textContent = text;
    console.log('truncating text', text);
    document.body.appendChild(span);
    const truncatedText = this.getTruncatedText(span, maxWidth, text);
    document.body.removeChild(span);

    return truncatedText;
  }

  private getTruncatedText(span: HTMLElement, maxWidth: number, text: string): string {
    let left = 0;
    let right = text.length;
    let truncatedText = text;

    while (left < right) {
      const mid = Math.floor((left + right) / 2);
      span.textContent = text.substring(0, mid) + '...';
      
      if (span.offsetWidth <= maxWidth) {
        left = mid + 1;
        truncatedText = span.textContent;
      } else {
        right = mid;
      }
    }

    return truncatedText;
  }
}
