import { HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { HttpService } from '../services/http.service';

const etaEmitter = new Subject<number>(); 
const allowedList:any = [];
const notAllowedList = ['authenticate', 'logout', 'force-login', 'calculate-time', 'save-time', 'check-session','/prior_art_search/get_pas_references/','/prior_art_search/get_reference_tiers/','/prior_art_search/get_reference_tiers/']// 'targetCompanies', 'extractCompanyInformation','productInformation','revenueInformation'  ];

export const ETAInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<any> => {
  const httpService = inject(HttpService);
  
  if (!isAllowed(req.url)) {
    return next(req);
  }

  console.log('ETAInterceptor:', req.url);

  const startTime = performance.now();

  
  const apiDetails = {
    api_name: req.url,
    request_size: JSON.stringify(req.body || '').length,
    response_size: 0, 
    eta: 0, 
  };

  let existingDetails={
    api_name: req.url,
    request_size: JSON.stringify(req.body || '').length,
    response_size: 0, 
    eta: 0, 
    number_of_saves:0,
  }

  callCalculateTimeApi(httpService, apiDetails).then(response => {
    let eta = response?.eta??null
    existingDetails={...existingDetails, ...response}
    if(eta!==null){
      console.log(""+apiDetails.request_size/existingDetails.request_size+" "+apiDetails.request_size+" "+existingDetails.request_size)
      eta=eta*apiDetails.request_size/existingDetails.request_size
      eta*=1.5
    }
    if (eta !== null) {
      etaEmitter.next(eta);
    }
  });

  return next(req).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        const endTime = performance.now();
        let eta = endTime - startTime;
        eta = eta / 1000;

        
        apiDetails.response_size = JSON.stringify(event.body || '').length;
        apiDetails.eta = eta;

        
        existingDetails.eta=(existingDetails.eta*existingDetails.number_of_saves+eta)/(existingDetails.number_of_saves+1)
        existingDetails.response_size=(existingDetails.response_size*existingDetails.number_of_saves+apiDetails.response_size)/(existingDetails.number_of_saves+1)
        existingDetails.request_size=(existingDetails.request_size*existingDetails.number_of_saves+apiDetails.request_size)/(existingDetails.number_of_saves+1)
        existingDetails.number_of_saves+=1


        
        callSaveTimeApi(httpService, existingDetails);
      }
    })
  );
};

function isAllowed(url: string): boolean {
  let spliturl:any=url.split('/')
  spliturl=spliturl[spliturl.length-1];
  console.log('spliturl',spliturl)
  console.log('spliturl check', allowedList.includes(spliturl), notAllowedList.includes(spliturl))
  return (allowedList.includes(spliturl)||allowedList.length==0) && !notAllowedList.includes(spliturl);
}

async function callCalculateTimeApi(httpService: HttpService, apiDetails: any): Promise<any> {
  const calculateTimeApiUrl = '/api-metrics/calculate-time';
  try {
    const response = await httpService.post(calculateTimeApiUrl, apiDetails);
    return response
  } catch (error) {
    console.error('Error calling calculate-time API:', error);
    return null;
  }
}

async function callSaveTimeApi(httpService: HttpService, apiDetails: any): Promise<void> {
  const saveTimeApiUrl = '/api-metrics/save-time';
  try {
    await httpService.post(saveTimeApiUrl, apiDetails);
    console.log('API details saved successfully.');
  } catch (error) {
    console.error('Error saving API details:', error);
  }
}

export function getEtaEmitter(): Observable<number> {
  return etaEmitter.asObservable();
}
