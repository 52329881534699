import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-progress-bar-loader',
  templateUrl: './progress-bar-loader.component.html',
  styleUrls: ['./progress-bar-loader.component.css'],
  standalone: true,
  imports:[CommonModule, MatProgressSpinner]
})
export class ProgressBarLoaderComponent implements OnInit {
  @Input() loadingText: string = 'Loading, please wait';
  @Input() percentageCompletion: number = -1;
  @Input() isError: boolean = false;
  ngOnInit(): void {
    // if (this.percentageCompletion === -1) {
    //   this.autoCompleteLoader();
    // }
  }

  autoCompleteLoader(): void {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 1;
      this.percentageCompletion = progress;
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 0); // Completes in 2 seconds
  }
}