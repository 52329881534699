import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { getEtaEmitter } from '../../interceptors/eta.interceptor';

@Component({
  selector: 'app-progress-bar-loader',
  templateUrl: './progress-bar-loader.component.html',
  styleUrls: ['./progress-bar-loader.component.css'],
  standalone: true,
  imports:[CommonModule, MatProgressSpinner]
})
export class ProgressBarLoaderComponent implements OnInit {
  @Input() loadingText: string = 'Loading, please wait';
  @Input() percentageCompletion: number = -1;
  @Input() isError: boolean = false;
  @Input() override_Eta:any=null;

  etaStartValue: number = 0;
  etaKeyword="ETA: Under"
  ngOnInit(): void {
    // if (this.percentageCompletion === -1) {
    //   this.autoCompleteLoader();
    // }
    // alert(this.override_Eta)
    if(this.override_Eta){
      this.eta_value=this.override_Eta
      this.startEtaTimer()
    }
    else
    {
      getEtaEmitter().subscribe((eta) => {
        if(this.override_Eta)
        {
          return
        }
        this.eta_value+=eta
        this.stopEtaTimer()
        this.startEtaTimer()
        
      });
    }
  }

  ngOnChanges(changes: SimpleChanges ): void {
    // if (this.percentageCompletion === -1) {
    //   this.autoCompleteLoader();
    // }
    console.log('on changes', changes)
    // check if ovveride eta has changed, if it was previously 0 or null then update overrride eta and start timer
    if(changes['override_Eta'] && changes['override_Eta'].previousValue==0 || changes['override_Eta'].previousValue==null){
      this.eta_value=this.override_Eta
      this.startEtaTimer()
    }
  }

  eta_value:any=null;
  eta_interval:any=null;

  constructor() {
   
  }

  startEtaTimer(){
    this.etaStartValue=this.eta_value;
    this.eta_interval=setInterval(()=>{
      if(this.isError){
        this.stopEtaTimer();
        return
      }
      if(this.eta_value!==null||this.eta_value>0){
        this.eta_value-=1
      }
      else{
        this.stopEtaTimer();
      }
    }, 1000)
  }

  stopEtaTimer(){
    clearInterval(this.eta_interval)
  }


  autoCompleteLoader(): void {
    let progress = 0;
    const interval = setInterval(() => {
      if(this.isError){
        clearInterval(interval);
        return
      }
      progress += 1;
      this.percentageCompletion = progress;
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 0); 
  }
  get refined_eta_value(){
    let eta_value=this.eta_value>0?this.eta_value:0;
    const hours = Math.floor(eta_value / 3600);
    const minutes = Math.floor((eta_value % 3600) / 60);
    const seconds = Math.floor(eta_value % 60);
    let string=''
    if(hours>0){
      string+=`${hours} hours `
    }
    if(minutes>=0){
      string+=`${minutes+1} minutes `
    }
    // if(seconds>0){
    //   string+=`${seconds} seconds `
    // }
    return string;
  }

  get progress_value():any{
    // console.log('get progress value')
    // calculate percentage completion if eta and start eta are aviailable
    if(this.eta_value!==null && this.etaStartValue!==null && this.etaStartValue>0){
      return Math.floor((this.etaStartValue-this.eta_value)/this.etaStartValue*100)
    }
    else return null;
  }

  get progress_determined_value(){
    if(this.percentageCompletion==-1){
      // console.log('progress determined value', this.progress_value)
      if(this.progress_value>=99){
        this.etaKeyword="Taking longer than expected"
      }
      else{
        this.etaKeyword="ETA: Under"
      }
      return this.progress_value>99?99:this.progress_value;
    }
    else return this.percentageCompletion
  }
}