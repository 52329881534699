<app-progress-bar-loader *ngIf="isLoading" [loadingText]="loadingText" [isError]="isError"></app-progress-bar-loader>

<div *ngIf="!isLoading" class="industry-analysis-container">
    <div *ngIf="currentStage > 1" class="header-controls">
        <div class="left-controls">

            <button mat-icon-button (click)="goBack()" class="back-button">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="title">{{ titleText }}</span>
        </div>
        <div class="right-controls">
            <button class="assist-button cancel-button" *ngIf="currentStage==4" (click)="toggleAssistMode()">{{assistMode?"Unassist":"Assist"}}</button>
            <button class="cancel-button" (click)="onClickCancel()">Cancel</button>
            <button class="primary-button" *ngIf="currentStage==2" [class.btn-disabled]="assistMode" (click)="proceedToTargetProducts()">Next</button>
            <button class="primary-button" *ngIf="currentStage==3" [class.btn-disabled]="assistMode" (click)="proceedToRevenueIdentification()">Next</button>
            <button class="primary-button" *ngIf="currentStage==4" [class.btn-disabled]="assistMode" (click)="onFinalNext()">Next</button>
        </div>
    </div>
    <div *ngIf="currentStage === 2" class="table-container target-scouting">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Current Assignee</th>
                    <th>Targeted Company
                        <!-- <button class="header-icon" (click)="toggleEditCompanies()">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                </button> -->
                    </th>
                    <th>Global Headquarters
                        <!-- <button class="header-icon" (click)="toggleEditingExtraDetails('Global Headquarters')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> -->
                        <!-- </button> -->
                    </th>
                    <th>US Headquarters
                        <!-- <button class="header-icon" (click)="toggleEditingExtraDetails('US Headquarters')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                      </button> -->
                    </th>
                    <th>State of Incorporation
                        <!-- <button class="header-icon" (click)="toggleEditingExtraDetails('State of Incorporation')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                      </button> -->

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of patentDetails; let sno = index">
                    <td>{{ sno + 1 }}</td>
                    <td>
                        <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                        <!-- {{patent.patentNumber }} -->

                    </td>
                    <td>{{patent.currentAssignee}}</td>
                    <td>
                        <div class="d-flex" *ngFor="let company of patent.companies; let i=index">
                            <input *ngIf="isEditingCompanies" (input)="handleCompanyChange($event, company)" [(ngModel)]="company.name"> <span *ngIf="!isEditingCompanies" [title]="company.name" appTruncateTextWidth>
                                {{i+1}}.{{ company.name }}
                                <ngx-popover>
                                <button class="sources-button">
                                    <img src="assets/images/link-icon.png" alt="info" />
                                </button>

                                <ng-template ngx-popover-template>
                                    <div class="sources-content">
                                        Source: <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                                            https://patents.google.com/patent/{{ patent.patentNumber  }}
                                        </a>
                                    </div>
                                </ng-template>

                                <ngx-arrow padding="8" />
                            </ngx-popover></span>
                            <button mat-icon-button (click)="deleteCompany(patent, i)" *ngIf="isEditingCompanies">
                <img src="assets/images/minus-circle.png" alt="delete" />
                </button>

                        </div>
                        <button mat-icon-button (click)="addCompany(patent)" *ngIf="isEditingCompanies">
                <img src="assets/images/plus-circle.png" alt="add" />
              </button>
                    </td>
                    <td>
                        <div *ngFor="let company of patent.companies">
                            <ng-container *ngIf="company.new!=true">
                                <input *ngIf="editingExtraDetails['Global Headquarters']" [(ngModel)]="company.companyInfo['Global Headquarters'].response">
                                <span *ngIf="!editingExtraDetails['Global Headquarters']">{{ company.companyInfo['Global Headquarters'].response }}</span>
                                <ngx-popover>
                                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>

                                    <ng-template ngx-popover-template>
                                        <div class="sources-content">
                                            Source:<span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['Global Headquarters'].sourceLink)"></span>
                                            <!-- <a [href]="company.companyInfo['Global Headquarters'].sourceLink" target="_blank">{{ company.companyInfo['Global Headquarters'].sourceLink }}</a> -->
                                        </div>
                                    </ng-template>

                                    <ngx-arrow padding="8" />
                                </ngx-popover>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        <div *ngFor="let company of patent.companies">
                            <ng-container *ngIf="company.new!=true">
                                <input *ngIf="editingExtraDetails['US Headquarters']" [(ngModel)]="company.companyInfo['US Headquarters'].response">
                                <span *ngIf="!editingExtraDetails['US Headquarters']">{{ company.companyInfo['US Headquarters'].response }}</span>
                                <ngx-popover>
                                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>

                                    <ng-template ngx-popover-template>
                                        <div class="sources-content">
                                            Source:<span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['US Headquarters'].sourceLink)"></span>
                                            <!-- <a [href]="company.companyInfo['US Headquarters'].sourceLink" target="_blank">{{ company.companyInfo['US Headquarters'].sourceLink }}</a> -->
                                        </div>
                                    </ng-template>

                                    <ngx-arrow padding="8" />
                                </ngx-popover>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        <div *ngFor="let company of patent.companies">
                            <ng-container *ngIf="company.new!=true">
                                <input *ngIf="editingExtraDetails['State of Incorporation']" [(ngModel)]="company.companyInfo['State of Incorporation'].response">
                                <span *ngIf="!editingExtraDetails['State of Incorporation']">{{ company.companyInfo['State of Incorporation'].response }}</span>
                                <ngx-popover>
                                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>

                                    <ng-template ngx-popover-template>
                                        <div class="sources-content">
                                            Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['State of Incorporation'].sourceLink)"></span>
                                            <!-- <a [href]="company.companyInfo['State of Incorporation'].sourceLink" target="_blank">{{ company.companyInfo['State of Incorporation'].sourceLink }}</a> -->
                                        </div>
                                    </ng-template>

                                    <ngx-arrow padding="8" />
                                </ngx-popover>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <div *ngIf="currentStage === 3" class="table-container target-products">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Current Assignee</th>
                    <th>Targeted Company</th>
                    <th>Targeted Product
                        <button class="header-icon" mat-icon-button (click)="toggleEditProducts()">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                </button>
                    </th>
                    <th>Global Headquarters<button class="header-icon" mat-icon-button (click)="toggleEditingExtraDetails('Global Headquarters')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                      </button></th>
                    <th>US Headquarters<button class="header-icon" mat-icon-button (click)="toggleEditingExtraDetails('US Headquarters')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                      </button></th>
                    <th>State of Incorporation<button class="header-icon" mat-icon-button (click)="toggleEditingExtraDetails('State of Incorporation')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                      </button></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of patentDetails; let sno = index">
                    <td>{{sno + 1 }}</td>
                    <td>
                        <!-- {{ patent.patentNumber }} -->
                        <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                    </td>
                    <td>{{patent.currentAssignee}}</td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies;let k=index">
                            <div appTruncateTextWidth>
                                {{k+1}}. {{ company.name }}
                                <ngx-popover>
                                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>

                                    <ng-template ngx-popover-template>
                                        <div class="sources-content">
                                            Source: <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                                                https://patents.google.com/patent/{{ patent.patentNumber  }}
                                            </a>
                                        </div>
                                    </ng-template>

                                    <ngx-arrow padding="8" />
                                </ngx-popover>
                            </div>
                            <!-- <ng-container *ngFor="let product of company.products;let j= index">
                                <div *ngIf="j!=0">
                                </div>
                            </ng-container> -->
                            <ng-container *ngFor="let product of company.products;let i= index">
                                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div *ngFor="let product of company.products; let i=index" [class.last-item]="i==company.products.length-1">
                                <div class="item-edit-controls">
                                    <input *ngIf="isEditingProducts" [(ngModel)]="product.name">
                                    <!-- create a delete button -->
                                    <button mat-icon-button (click)="deleteProduct(company, i)" *ngIf="isEditingProducts">
                        <img src="assets/images/minus-circle.png" alt="delete" />
                        </button>
                                </div>
                                <span appTruncateTextWidth *ngIf="!isEditingProducts">{{i+1}}. {{ product.name }} <ngx-popover>
                                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>

                                    <ng-template ngx-popover-template>
                                        <div class="sources-content">
                                            Source:<span [innerHTML]="getLinkOrAiEstimate(product.sourceLink)"></span>
                                <!-- <a [href]="product.sourceLink" target="_blank">{{ product.sourceLink }}</a> -->
                            </div>
                            </ng-template>

                            <ngx-arrow padding="8" />
                            </ngx-popover>
                            </span>

    </div>
    <button mat-icon-button (click)="addProduct(company)" *ngIf="isEditingProducts">
                                <img src="assets/images/plus-circle.png" alt="add" />
                              </button>
    </ng-container>
    </td>
    <td>
        <ng-container *ngFor="let company of patent.companies; let j=index">
            <ng-container *ngIf="company.new!=true">
                <div>
                    <input *ngIf="editingExtraDetails['Global Headquarters']" [(ngModel)]="company.companyInfo['Global Headquarters'].response">
                    <span *ngIf="!editingExtraDetails['Global Headquarters']">{{ company.companyInfo['Global Headquarters'].response }}</span>
                    <ngx-popover>
                        <button class="sources-button">
                            <img src="assets/images/link-icon.png" alt="info" />
                        </button>
                        <ng-template ngx-popover-template>
                            <div class="sources-content">
                                Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['Global Headquarters'].sourceLink)"></span>
                            </div>
                        </ng-template>
                        <ngx-arrow padding="8" />
                    </ngx-popover>
                </div>
                <ng-container *ngFor="let product of company.products;let i= index">
                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </td>
    <td>
        <ng-container *ngFor="let company of patent.companies; let j=index">
            <ng-container *ngIf="company.new!=true">
                <div>
                    <input *ngIf="editingExtraDetails['US Headquarters']" [(ngModel)]="company.companyInfo['US Headquarters'].response">
                    <span *ngIf="!editingExtraDetails['US Headquarters']">{{ company.companyInfo['US Headquarters'].response }}</span>
                    <ngx-popover>
                        <button class="sources-button">
                            <img src="assets/images/link-icon.png" alt="info" />
                        </button>
                        <ng-template ngx-popover-template>
                            <div class="sources-content">
                                Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['US Headquarters'].sourceLink)"></span>
                            </div>
                        </ng-template>
                        <ngx-arrow padding="8" />
                    </ngx-popover>
                </div>
                <ng-container *ngFor="let product of company.products;let i= index">
                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </td>
    <td>
        <ng-container *ngFor="let company of patent.companies">
            <ng-container *ngIf="company.new!=true">
                <div>
                    <input *ngIf="editingExtraDetails['State of Incorporation']" [(ngModel)]="company.companyInfo['State of Incorporation'].response">
                    <span *ngIf="!editingExtraDetails['State of Incorporation']">{{ company.companyInfo['State of Incorporation'].response }}</span>
                    <ngx-popover>
                        <button class="sources-button">
                            <img src="assets/images/link-icon.png" alt="info" />
                        </button>
                        <ng-template ngx-popover-template>
                            <div class="sources-content">
                                Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['State of Incorporation'].sourceLink)"></span>
                            </div>
                        </ng-template>
                        <ngx-arrow padding="8" />
                    </ngx-popover>
                </div>
                <ng-container *ngFor="let product of company.products;let i= index">
                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </td>
    </tr>
    </tbody>
    </table>

</div>

<div *ngIf="currentStage === 4" class="table-container revenue-identification">
    <table>
        <thead>
            <tr>
                <th>S. No.</th>
                <th>Patent Number</th>
                <th>Current Assignee</th>
                <th>Targeted Company</th>
                <th>Company Revenue</th>
                <th>Targeted Product</th>
                <th>Product Revenue</th>
                <th>Infringement Score</th>
                <th>Global Headquarters</th>
                <th>US Headquarters </th>
                <th>State of Incorporation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let patent of patentDetails; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                    <!-- {{ patent.patentNumber }} -->
                    <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                </td>
                <td>{{patent.currentAssignee}}</td>
                <td>
                    <ng-container *ngFor="let company of patent.companies">
                        <div appTruncateTextWidth>
                            <input *ngIf="isEditingCompanies" (input)="handleCompanyChange($event, company)" [(ngModel)]="company.name"> <span *ngIf="!isEditingCompanies" [title]="company.name" appTruncateTextWidth>
                          
                            {{ company.name }}
                            <ngx-popover>
                                <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>

                                <ng-template ngx-popover-template>
                                    <div class="sources-content">
                                        Source: <span [innerHTML]="getLinkOrAiEstimate(company.sourceLink)"></span>
                            <!-- <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                                                https://patents.google.com/patent/{{ patent.patentNumber  }}
                                            </a> -->
                        </div>
                        </ng-template>

                        <ngx-arrow padding="8" />
                        </ngx-popover>
                        <button mat-icon-button (click)="deleteCompany(patent, i)" *ngIf="isEditingCompanies">
                                <img src="assets/images/minus-circle.png" alt="delete" />
                                </button>
                        </span>

</div>
<ng-container *ngFor="let product of company.products;let k= index">
    <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
    </div>
</ng-container>
</ng-container>
</td>
<td>
    <ng-container *ngFor="let company of patent.companies">

        <div class="revenue-edit-controls">
            <button *ngIf="assistMode" class="img-icon-button" (click)="showRevenueControls(company)">
                                <img *ngIf="company.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!company.showRevenue" src="assets/images/plier-icon.png">
                            </button>
            <div appTruncateTextWidth>
                {{ company.companyInfo.Revenue.response }}
                <ngx-popover>
                    <button class="sources-button">
                                            <img src="assets/images/link-icon.png" alt="info" />
                                        </button>

                    <ng-template ngx-popover-template>
                        <div class="sources-content">
                            Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo.Revenue.sourceLink)"></span>
                        </div>
                    </ng-template>

                    <ngx-arrow padding="8" />
                </ngx-popover>
                <!-- <button mat-icon-button (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                    </button> -->
            </div>
            <ng-container *ngIf="assistMode">
                <button *ngIf="company.showRevenue" class="img-icon-button" (click)="openPopup(patent.patentNumber, '', company.name)">
                    <img src="assets/images/edit-link-icon.png">
                </button>
                <button *ngIf="company.showRevenue" class="img-icon-button" (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <img src="assets/images/upload-file-icon.png">
                </button>
            </ng-container>
        </div>


        <ng-container *ngFor="let product of company.products;let k= index">
            <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
            </div>
        </ng-container>
    </ng-container>

</td>
<td>
    <ng-container *ngFor="let company of patent.companies">
        <ng-container *ngFor="let product of company.products;let ind=index">

            <div appTruncateTextWidth [class.last-item]="ind==company.products.length-1">
                <div class="item-edit-controls">
                    <input *ngIf="isEditingProducts" [(ngModel)]="product.name">
                    <!-- create a delete button -->
                    <button mat-icon-button (click)="deleteProduct(company, ind)" *ngIf="isEditingProducts">
                        <img src="assets/images/minus-circle.png" alt="delete" />
                        </button>
                </div>

                <ng-container *ngIf="!isEditingProducts">
                    {{ product.name }}
                </ng-container>
                <ngx-popover>
                    <button class="sources-button">
                                            <img src="assets/images/link-icon.png" alt="info" />
                                        </button>

                    <ng-template ngx-popover-template>
                        <div class="sources-content">
                            Source: <span [innerHTML]="getLinkOrAiEstimate(product.sourceLink)"></span>
                            <!-- <a [href]="product.sourceLink" target="_blank">{{ product.sourceLink }}</a> -->
                        </div>
                    </ng-template>

                    <ngx-arrow padding="8" />
                </ngx-popover>
            </div>
        </ng-container>
    </ng-container>
</td>
<td>
    <ng-container *ngFor="let company of patent.companies">
        <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">
            <button *ngIf="assistMode" class="img-icon-button" (click)="showProductRevenueControls(product)">
                                <img *ngIf="product.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!product.showRevenue" src="assets/images/plier-icon.png">
                            </button>
            <div appTruncateTextWidth [class.last-item]="ind==company.products.length-1">
                {{ product.revenue.response }}
                <ngx-popover>
                    <button class="sources-button">
                                            <img src="assets/images/link-icon.png" alt="info" />
                                        </button>

                    <ng-template ngx-popover-template>
                        <div class="sources-content">
                            Source: <span [innerHTML]="getLinkOrAiEstimate(product.revenue.sourceLink)"></span>
                        </div>
                    </ng-template>

                    <ngx-arrow padding="8" />
                </ngx-popover>
                <!-- <ng-container *ngIf="product.revenue.response">
                                        {{product.revenue?.response?.length()}}
                                    </ng-container> -->
            </div>
            <ng-container *ngIf="assistMode">

                <button *ngIf="product.showRevenue" class="img-icon-button" (click)="openPopup(patent.patentNumber, product.name, company.name)">
                                    <img src="assets/images/edit-link-icon.png">
                                </button>
                <button *ngIf="product.showRevenue" class="img-icon-button" (click)="openFileUploadPopup(patent.patentNumber, product.name, company.name)">
                                    <img src="assets/images/upload-file-icon.png">
                                </button>
            </ng-container>
        </div>
    </ng-container>
</td>
<td>

    {{patentInfringementScoreData[patent.patentNumber]}}
    <app-validity-score [infringementScore]="true" [score]="patentInfringementScoreData[patent.patentNumber]"></app-validity-score>
</td>
<td>
    <ng-container *ngFor="let company of patent.companies; let j=index">
        <ng-container *ngIf="company.new!=true">
            <div>
                <input *ngIf="editingExtraDetails['Global Headquarters']" [(ngModel)]="company.companyInfo['Global Headquarters'].response">
                <span *ngIf="!editingExtraDetails['Global Headquarters']">{{ company.companyInfo['Global Headquarters'].response }}</span>
                <ngx-popover>
                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>
                    <ng-template ngx-popover-template>
                        <div class="sources-content">
                            Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['Global Headquarters'].sourceLink)"></span>
                        </div>
                    </ng-template>
                    <ngx-arrow padding="8" />
                </ngx-popover>
            </div>
            <ng-container *ngFor="let product of company.products;let i= index">
                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</td>
<td>
    <ng-container *ngFor="let company of patent.companies; let j=index">
        <ng-container *ngIf="company.new!=true">
            <div>
                <input *ngIf="editingExtraDetails['US Headquarters']" [(ngModel)]="company.companyInfo['US Headquarters'].response">
                <span *ngIf="!editingExtraDetails['US Headquarters']">{{ company.companyInfo['US Headquarters'].response }}</span>
                <ngx-popover>
                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>
                    <ng-template ngx-popover-template>
                        <div class="sources-content">
                            Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['US Headquarters'].sourceLink)"></span>
                        </div>
                    </ng-template>
                    <ngx-arrow padding="8" />
                </ngx-popover>
            </div>
            <ng-container *ngFor="let product of company.products;let i= index">
                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</td>
<td>
    <ng-container *ngFor="let company of patent.companies">
        <ng-container *ngIf="company.new!=true">
            <div>
                <input *ngIf="editingExtraDetails['State of Incorporation']" [(ngModel)]="company.companyInfo['State of Incorporation'].response">
                <span *ngIf="!editingExtraDetails['State of Incorporation']">{{ company.companyInfo['State of Incorporation'].response }}</span>
                <ngx-popover>
                    <button class="sources-button">
                                        <img src="assets/images/link-icon.png" alt="info" />
                                    </button>
                    <ng-template ngx-popover-template>
                        <div class="sources-content">
                            Source: <span [innerHTML]="getLinkOrAiEstimate(company.companyInfo['State of Incorporation'].sourceLink)"></span>
                        </div>
                    </ng-template>
                    <ngx-arrow padding="8" />
                </ngx-popover>
            </div>
            <ng-container *ngFor="let product of company.products;let i= index">
                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</td>
</tr>
</tbody>
</table>

</div>
</div>