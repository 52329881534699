import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScoreLegendComponent } from "../common/score-legend/score-legend.component";
import { ValidityScoreComponent } from "../common/validity-score/validity-score.component";
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

declare var html2pdf: any;

@Component({
  selector: 'app-sniff-report',
  standalone: true,
  imports: [CommonModule, ScoreLegendComponent, ValidityScoreComponent],
  templateUrl: './sniff-report.component.html',
  styleUrl: './sniff-report.component.css'
})
export class SniffReportComponent {

  constructor() { }

  @Input () inputData:any=[];
  @Input () validitySkip:boolean=false;
  @Input () infringementData:any=[];
  @Input () validityData:any=[];
  @Input () industryData:any=[];

  exportUI:boolean=false;
  exportingState=false;

  currentTab="industry";

  selectedPatentIndex:any=null;
  infringementArray:any[]=[];
  claimChartData:any[]=[];

  ngOnInit(){
    console.log('inputdata', this.inputData)
    console.log('infringement data',this.infringementData)
    console.log(this.validityData.referenceTableMap)
    this.infringementArray=this.infringementData.patentDetails;
    this.claimChartData=this.infringementData.claimChartData;

  }

  getFilteredClaimChartData(patentNumber:string){
    return this.claimChartData.filter((item:any)=>item.patent_number==patentNumber)
  }
  getFilteredIndustryData(patentNumber:string){
    return this.industryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getSummaryData(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getValidityScoreCount(){
    return this.validityData.summaryData.filter((x:any)=>x.score>7).length
  }

  getValidityScore(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)[0].score
  }
  referenceTableOpenMapObj:any={};
  toggleReferenceTable(patentNumber:any, referenceNumber:any){
    if(!this.referenceTableOpenMapObj[patentNumber]){
      this.referenceTableOpenMapObj[patentNumber]={};
      this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
  }

  referenceTableOpenMap(patentNumber:any, referenceNumber:any, index:any){
    // map based on patentNumber and further referenceNumber. If the reference Object does not exist, create a new object with the referenceNumber as key and value as false
    if(!this.referenceTableOpenMapObj[patentNumber]){
      if(index==0&&this.referenceTableOpenMapObj[patentNumber]===undefined){
        this.referenceTableOpenMapObj[patentNumber]={};
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else
      this.referenceTableOpenMapObj[patentNumber]={};
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
    return this.referenceTableOpenMapObj[patentNumber][referenceNumber];
  }

  getScoreValue(){
    if(this.selectedPatentIndex!=null){
      if(this.currentTab=='pas')
        return this.getValidityScore(this.inputData[this.selectedPatentIndex].patentNumber)

      return this.infringementData['patentInfringementScoreData'][this.inputData[this.selectedPatentIndex].patentNumber]
    }
    return 0;
  }

  setSelectedPatentIndex(patentNumber:string){
    console.log(patentNumber)
    let index=this.inputData.findIndex((item:any)=>item.patentNumber==patentNumber);
    this.selectedPatentIndex=index;
    console.log(index, this.inputData[index])
  }

  exportPDF(){
    this.exportingState=true;
    this.exportUI=true;
    setTimeout(() => {
    var element:any = document.getElementById('exportable-ui');
    if(this.selectedPatentIndex!=null){
      element=document.getElementById('major-report');
      console.log(element)
    }
    let opt={
      filename:'sniff-report.pdf',
      width: 2500,
      height:100,

    }
      
    html2canvas(element).then(canvas => {
      //$("#previewBeforeDownload").html(canvas);
      var imgData = canvas.toDataURL("image/jpeg",1);
      var pdf = new jsPDF("p", "px", "a2");
      // var pageWidth = pdf.internal.pageSize.getWidth();
      var pageWidth=pdf.internal.pageSize.getWidth();
      var pageHeight = pdf.internal.pageSize.getHeight();
      var imageWidth = canvas.width;
      var imageHeight = canvas.height;

      var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
      //pdf = new jsPDF(this.state.orientation, undefined, format);
      pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
      pdf.save("sniff-report.pdf");
  });

      setTimeout(() => {
        
        this.exportingState=false;
        this.exportUI=false;
      }, 100);
      // var printWindow:any = window.open('', '', 'height=2000px,width=2000px');
      //       printWindow.document.write('<html><head><title>DIV Contents</title>');
      //       // add contents of sniff-report.css from assets/css/sniff-report.css
      //       printWindow.document.write('<link rel="stylesheet" href="assets/css/sniff-report.css" type="text/css" />');            
      //       printWindow.document.write('</head><body >');
      //       printWindow.document.write(element?.innerHTML);
      //       printWindow.document.write('</body></html>');
      //       printWindow.document.close();
      //       printWindow.print();
    }, 10);
    }

    calculateInfringedCompanies(){
        
    }

    calculateRevenue(){
      
    }

    changeIndex(index:number){
      if(this.selectedPatentIndex+index>=0 && this.selectedPatentIndex+index<this.inputData.length){
        this.selectedPatentIndex+=index;
      }
    }

}