import { HttpRequest, HttpHandlerFn, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { inject } from '@angular/core';

export const AuthInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  // check if request is for authenticate, if so skip
  if (req.url.includes('authenticate')) {
    return next(req);
      
  }
  
  let sessionId = localStorage.getItem('sessionId');
  const dataService= inject(DataService)

  if (!sessionId) {
    sessionId='uninitialized'
  }
    const clonedRequest = req.clone({
      setHeaders: {
      "Authorization": "Bearer "+sessionId
      }
    });

    return next(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        // check erro status
        let status=error.status

        console.log('error status', status)
        
        if (error.status === 401||error.status === 0) {
          dataService.updateLogOutObservable(1);
        }
        return throwError(error);
      })
    );
  

  return next(req);
};
