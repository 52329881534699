import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule} from '@angular/common/http';
import { AppConstants } from '../../constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  config: any;
  serverUrl="https://stagingapi.lumenci.ai"

  constructor(
    private http: HttpClient
  ) {
    this.config = AppConstants.SERVER_CONFIG;
    // this.serverUrl = this.config.URL;
  }

  // POST method
  post(url: string, body = <any>{}, { params = {}, headers = {}, responseType }: { params?: any, headers?: any, responseType?: any } = {}): Promise<any> {
    console.log(this.serverUrl + url);
    return this.http
      .post(this.serverUrl + url, body, { params, headers, responseType })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // POST with form data
  postFormData(url: string, formData = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .post(this.serverUrl + url, formData, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  postFormDataWithBody(url: string, body = <any>{}, formData = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    const formDataBody = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataBody.append(key, formData[key]);
    });
    formDataBody.append('body', JSON.stringify(body));
    return this.http
      .post(this.serverUrl + url, formDataBody, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // DELETE method
  delete(url: string, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .delete(this.serverUrl + url, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // GET method
  get(url: string, { params = {}, headers = {}, responseType }: { params?: any, headers?: any, responseType?: any } = {}): Promise<any> {
    return this.http
      .get(this.serverUrl + url, { params, headers, responseType })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // GET third-party URL (different base URL)
  getThirdPartyUrl(url: string, { params = {}, headers = {}, responseType }: { params?: any, headers?: any, responseType?: any } = {}): Promise<any> {
    return this.http
      .get(url, { params, headers, responseType })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // PUT method
  put(url: string, body = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .put(this.serverUrl + url, body, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // PATCH method
  patch(url: string, body = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .patch(this.serverUrl + url, body, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // Helper function to extract response data
  private extractData(res: any) {
    const body = res;
    return body || {};
  }

  // Error handler function
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.error || error);
  }
}
