import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { NavigationEnd, Router, RouterEvent, RouterModule } from '@angular/router';
import { IndustryAnalysisComponent } from "../industry-analysis/industry-analysis.component";
import { ProgressBarComponent } from "../common/progress-bar/progress-bar.component";
import { CommonModule } from '@angular/common';
import { UploadComponent } from '../upload/upload.component';
import { InfringementAnalysisComponent } from "../infringement-analysis/infringement-analysis.component";
import { PriorArtSearchComponent } from "../prior-art-search/prior-art-search.component";
import { PostUploadComponent } from "../post-upload/post-upload.component";
import { SniffReportComponent } from "../sniff-report/sniff-report.component";
import { ScoreLegendComponent } from '../common/score-legend/score-legend.component';
import { LoginPopupComponent } from '../common/popups/login-popup/login-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { DataService } from '../services/data.service';
import { ForceLoginPopupComponent } from '../common/popups/force-login-popup/force-login-popup.component';

@Component({
  selector: 'app-id-wrapper',
  standalone: true,
  imports: [HeaderComponent, RouterModule, IndustryAnalysisComponent, ProgressBarComponent, CommonModule, UploadComponent, InfringementAnalysisComponent, PriorArtSearchComponent, PostUploadComponent, SniffReportComponent, ScoreLegendComponent],
  templateUrl: './id-wrapper.component.html',
  styleUrl: './id-wrapper.component.css'
})
export class IdWrapperComponent implements OnInit {

  constructor(public dialog: MatDialog, public http:HttpService, public router:Router, public dataService:DataService) { }

  sessionId:any=null;
  userTimeoutLogoutMessage=`<b>Session Expired</b><br>
Your session has timed out due to inactivity. Please log in again to continue.`
userConcurrentLogoutMessage=`<b>Session Limit</b><br>
Maximum number of sessions exceeded, Please try again later or contact the product team.`

  ngOnInit(): void {
    this.handleLoginCheck(true)
    this.updateCurrentRoute()
    this.dataService.routeObservable.subscribe((data:any)=>{
      this.changeStep(data)
    })
    this.dataService.logOutObservable.subscribe((data:any)=>{
      console.log('data');
      console.log('logout observable updated')
      this.onLogout(this.userConcurrentLogoutMessage);
    });
    this.router.events.subscribe((val:any) => {
      // break val after /app/ and update currentRoute if not already updated
      if(val instanceof NavigationEnd){
        let url=val.url
        let urlArray=url.split('/app/')
        if(urlArray.length>1){
          console.log('urlArray', urlArray)
          if(urlArray[1]=='prompt-management'){
            this.currentRoute="upload"
            
          }
          else
            this.currentRoute=urlArray[1]
        }
          this.isOptimizedFlow=this.dataService.optimizedFlow
        
      }
    });
  

  // setInterval(this.handleLoginCheck.bind(this), 10000);
  }

isOptimizedFlow=false

  isInfringeRoute(){
    return this.currentRoute=="infringement-analysis"||this.currentRoute=="infringement-summary"||this.currentRoute=="industry-analysis"||this.currentRoute=="target-scouting"
  }

  handleLoginCheck(firstTime=false){
      let userLoggedIn = this.checkUserLoginStatus();
      let sessionId=window.localStorage.getItem('sessionId')
      if(sessionId){
        this.sessionId=sessionId
      }
      if (!userLoggedIn) {
        if(firstTime)
      this.openLoginDialog();
    else
      this.onLogout(this.userTimeoutLogoutMessage);
      } else {
      //   if(firstTime)
      // this.postUserLogin(null);
      }
  }

  checkUserLoginStatus(){
    let loginTime:any=window.localStorage.getItem('loginTime')
    let currentTime=new Date().getTime()
    let loginTimeInMs=parseInt(loginTime)
    let timeDiff=currentTime-loginTimeInMs
    console.log('timeDiff', timeDiff)
    if(timeDiff<18000000){
      return true
    }
    return false
  }

  postUserLogin(sessionId:any){
    let currentTime=new Date().getTime()
    window.localStorage.setItem('loginTime', currentTime.toString())
    this.sessionId=sessionId
    window.localStorage.setItem('sessionId', sessionId)
    this.checkLoginPolling();
  }

  checkLoginPolling(){
    // setInterval(this.loginCheckAPI.bind(this), 60000);
  }

  loginCheckAPI(){
    if(!this.sessionId){
      return
    }
    console.log(this.sessionId);
    if(this.sessionId==null||this.sessionId=='null'){
      alert('session Id identified as null. please check network calls')
    }
    this.http.post('/check-session', {sessionID:this.sessionId})
    .then((response:any)=>{
      if(response.status!='True'){
        this.onLogout(this.userConcurrentLogoutMessage);
      }
    })
    .catch((error:any)=>{
      this.onLogout(this.userConcurrentLogoutMessage);
    })
  }

  loginUser(username: any, password: any, forceLogin=false) {
    let reqHeaders={
      'Session-Id':'Uninitialized'
    }
    let url=forceLogin?'/force-login':'/authenticate'
   return this.http.post(url, {userName:username, password:password}, {
    headers:reqHeaders
   })
    .then((response:any)=>{
      console.log(response)
      let sessionId=response.access_token;
      console.log(sessionId)
      this.sessionId=sessionId
      // set username in local storage
      window.localStorage.setItem('username', username)
      this.dataService.updateUsernameObservable(username)
      // if(response.status==200){
      //   console.log( sessionId)
      //   this.postUserLogin(sessionId)
      // }
    })
  }

  onLogout(logoutMessage:any){
    console.log('logout message', logoutMessage)
    let hanldeLogout=()=>{
      window.localStorage.removeItem('loginTime')
      window.localStorage.removeItem('sessionId')
      window.localStorage.removeItem('username')
      this.sessionId=null
      this.currentRoute="null";
      this.currentRoute="upload"
      this.openLoginDialog(logoutMessage)
    }
    let username=window.localStorage.getItem('username')
    this.http.post('/logout', {userName:username})
    .then((response:any)=>{
      hanldeLogout(); 
    })
    .catch((error:any)=>{
      console.log('error', error)
      hanldeLogout();
    })
  }

  openLoginDialog(logoutMessage:any=null): void {

    const openDialogs = this.dialog.openDialogs;
    if (openDialogs.length > 0) {
      return;
    }
    const dialogRef = this.dialog.open(LoginPopupComponent, {
      disableClose: true,  // Disable backdrop click to close
      width: '400px',
      height: 'auto',
      data: {
        logoutMessage: logoutMessage||null,
        onSubmit: (formValue: any, onFailure:any, onNetworkFailure:any, onConcurrentFailure:any) => {
          console.log('Form submitted', formValue);
          this.loginUser(formValue.username, formValue.password)
          .then(()=>{
            this.postUserLogin(this.sessionId)
            dialogRef.close();
          })
          .catch((error:any)=>{
            let code=error.status
            console.log('LOGIN DIALOG ERROR CDODE', code, error)
            if(code==401)
            {

              onFailure()
            }
            else if (code==403){
              this.handleForceLoginDialog(formValue)
              dialogRef.close();
            }
            else{
              onNetworkFailure()
            }
            console.log('error', error)
          })
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed', result);
    });
  }

  handleForceLoginDialog(formValue:any){
    const dialogRef = this.dialog.open(ForceLoginPopupComponent, {
      disableClose: true,  // Disable backdrop click to close
      width: '400px',
      height: 'auto',
      data: {
        onForceLogin: ()=>{
          
          console.log('Form submitted', formValue);
          this.loginUser(formValue.username, formValue.password, true)
          .then(()=>{
            this.postUserLogin(this.sessionId)
            dialogRef.close();
          })
          .catch((error:any)=>{
            let code=error.status
            console.log('LOGIN DIALOG ERROR CDODE', code, error)
            
            // if(code==401)
            // {

            //   onFailure()
            // }
            // else{
            //   onNetworkFailure()
            // }
            console.log('error', error)
          })
        
      }
    }
    });
  }

  currentRoute="upload";
  inputData:any=[]
  industryData:any=[]
  infringementData:any={}
  pasData:any={}
  skipPas=false
  tempData:any=[]
  mainUploadData=[]

  updateCurrentRoute(){
    let url=window.location.pathname
    let urlArray=url.split('/')
    let currentRoute=urlArray[urlArray.length-1]
    if(currentRoute=="upload" || currentRoute=="industry-analysis" || currentRoute=="infringement-analysis" || currentRoute=="report" || currentRoute=="pas"){
      this.currentRoute=currentRoute
    }
  }

  setMainUploadData(data:any){
    
    this.mainUploadData=data
    
    data.forEach((element:any) => {
      console.log('got data', data)
      console.log(this.inputData, this.tempData)
      this.inputData.push(
        {
          /*{ section['Title'] }}</td>
                <td>{{ section['Current Assignee'] }}</td>
                <td>{{ section['Priority Date'] }}</td>
                <td>{{ section['Expiration Date'] }}</td>
                <td class="claims-cell" (click)="openClaimsDialog(section['Claims'])">
                    {{ section['Claims'][0].slice(0, 15) }}...
                </td>
                <td>{{ section['Technology'] }}</td>
                <td>{{ section['Independent Claims'] }}</td>
                <td>{{ section['Dependent Claims'] }}</td>
                <td>{{ section['Patent Status'] }}*/
          patentNumber:element["Publication Number"],
          currentAssignee:element["Current Assignee"],
          priorityDate:element["Priority Date"],
          expirationDate:element["Expiration Date"],
          claims:element["Claims"],
          technology:element["Technology"],
          independentClaims:element["Independent Claims"],
          dependentClaims:element["Dependent Claims"],
          patentStatus:element["Patent Status"],
          


        }
      )
    });
  }

  updateDataSameStructure(data:any, source=null, updateMainData=true){
    if(source=="pas"){
      this.pasData=data
    }
    if(source=="industry"){
      this.industryData=data
    }
    if(source=="infringement"){
      this.infringementData=data
      this.filterDataBasedOnInfringemnetSelection()
    }
    if(updateMainData){
      this.inputData=data
    }
  }

  filterDataBasedOnInfringemnetSelection(){
    let tempIndustryData=[]
    let tempPasData=[]
    console.log('inputData', this.inputData)
    console.log('infringementData', this.infringementData)
    console.log('industryData', this.industryData)
    console.log('pasData', this.pasData)
    // filter input data, industry data, pas data based on infringement data. make it so that only the companies in infringement data are there in industry data and only the patents in infringement data are there in all the others
    this.infringementData.patentDetails.forEach((element:any) => {
      this.inputData.forEach((inputElement:any) => {
        if(element["Patent Number"]==inputElement["patentNumber"]){
          tempPasData.push(inputElement)
        }
      });
      this.industryData.forEach((industryElement:any) => {
        if(element["Company"]==industryElement["Company"]){
          tempIndustryData.push(industryElement)
        }
      });
    });
  }


  changeStep(step:string){
    this.currentRoute=step
    this.router.navigate(['app',step]);
    
  }



  moduleBack(){
    if(this.currentRoute=="industry-analysis"){
      this.currentRoute="upload"
    }
    if(this.currentRoute=="infringement-analysis"){
      this.currentRoute="industry-analysis"
    }
    if(this.currentRoute=="report"){
      this.currentRoute="infringement-analysis"
    }
    if(this.currentRoute=="pas"){
      this.currentRoute="post-upload"
    }
  }

  OnClickNext(){
    this.currentRoute="pas"
    this.dataService.onClickNext()
  }

  onSkipPas(){
    this.dataService.onSkipPas()
    this.skipPas=true
    this.currentRoute="target-scouting"
  }

  getActiveStage(){
    let returnable=1;
    switch(this.currentRoute){
      case "target-scouting":
        returnable=1;
        break;
      case "infringement-analysis":
        returnable=2;
        break;
      case "industry-analysis":
        returnable=3;
        break;
      case "infringement-summary":
        returnable=4;
        break;
    }
    return returnable;
  }

  getPercentageCompletion(){
    let returnable=0;
    switch(this.currentRoute){
      case "target-scouting":
        returnable=10;
        break;
      case "infringement-analysis":
        returnable=35;
        break;
      case "industry-analysis":
        returnable=69
        break;
      case "infringement-summary":
        returnable=100;
        break;
    }
    return returnable;
  }

  handleGenerateReport(){
    this.currentRoute="report"
  }

  goHome(){
    this.currentRoute="upload"
  }
}
