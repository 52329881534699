<div class="popup-container">
    <h2 class="title">Session Limit Reached</h2>
    <p class="message">
        Concurrent session limit reached. Proceeding will force one of the existing active sessions to end. Would you like to proceed?
    </p>

    <div class="button-container">
        <button mat-button class="force-button" (click)="forceLogin()">Force Login</button>
        <button mat-button class="cancel-button" (click)="cancel()">Cancel</button>
    </div>
</div>